import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MiniFabButtonComponent } from '../../../buttons';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ICalScanConfig, TCalCalDocType } from '@calsale/scanovate';
import { ScanovateCameraComponent } from '../scanovate-camera/scanovate-camera.component';

@Component({
  selector: 'ctd-scanovate-input',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    MiniFabButtonComponent,
    ScanovateCameraComponent
  ],
  templateUrl: './scanovate-input.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScanovateInputComponent),
      multi: true
    }
  ],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('250ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('250ms', style({ opacity: 0 }))])
    ])
  ]
})
export class ScanovateInputComponent implements ControlValueAccessor {
  image$: Observable<string | null>;
  isScanovateOpen$: Observable<boolean>;
  isScanovateOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private imageBase64: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private onChange: (value: string[] | null) => void;
  private onTouched: () => void;

  /**
   * @description An output which emits a callback function, that removes the picture from the component, if called.
   * Used to enable parent component, to remove the picture by itself, and from within the current component.
   * The output is being emitted every time the remove button of the current component is clicked.
   */
  @Output()
  pendingReset = new EventEmitter<() => void>();

  @Output()
  pendingViewImage = new EventEmitter();

  @Output()
  pendingError = new EventEmitter();

  @Input()
  description: string;
  @Input()
  maxWidth: number;
  @Input()
  maxHeight: number;
  @Input()
  docType: TCalCalDocType;
  @Input()
  scanConfig: ICalScanConfig;
  @Input()
  enabled = true;

  @HostBinding('class')
  get hostClass() {
    return 'camera-input';
  }

  hasContent$: Observable<boolean>;

  constructor() {
    this.image$ = this.imageBase64.asObservable();
    this.isScanovateOpen$ = this.isScanovateOpen.asObservable();
    this.hasContent$ = this.image$.pipe(map((image) => Boolean(image?.length)));
  }

  /**
   * @description The method streams a value from the form into local subject, the value is consumed in the template via image$ member
   * @param value the value (base64 string) that is currently transimted from the formcontrol into this custom control
   */
  writeValue(value: string | null): void {
    if (value) this.onTouched();
    this.imageBase64.next(value);
  }

  /**
   * @description Emitted on registration of the form to the current form control
   * @param onChangeCallback A callback function that will be called every time an inner change occures, and will update the source from about new value
   */
  registerOnChange(onChangeCallback: (value: string[] | null) => void): void {
    this.onChange = onChangeCallback;
  }

  /**
   * @description An event callback that will be emitted when a picture has been taken.
   * The callback function will extract the image base64 data, draw it to an img html element, and update the source form with the change of the value.
   * @param event The js event metadata (includes target html element etc.).
   */
  async onPictureTaken(inputImagesBase64: string[]) {
    try {
      const resizedInputImageBase64Promises = inputImagesBase64.map((imageBase64) => this.resizeImage(imageBase64));
      const resizedInputImageBase64 = await Promise.all(resizedInputImageBase64Promises);

      if (inputImagesBase64) {
        this.onChange && this.onChange(resizedInputImageBase64);
        this.onTouched && this.onTouched();
        this.drawPicture(resizedInputImageBase64[0]);
      }
    } catch (e) {
      this.onChange && this.onChange(null);
      this.onTouched && this.onTouched();
      this.drawPicture(null);
    } finally {
      this.isScanovateOpen.next(false);
    }
  }

  /**
   * @description Emits to parent on request remove picture.
   */
  requestRemovePicture() {
    this.pendingReset.emit(this.removePicture.bind(this));
  }

  /**
   * @description Emits to parent on request view picture.
   */
  requestViewImage() {
    this.pendingViewImage.emit();
  }

  /**
   * @description Recives the image base64 string from the subject behavior which connected to an img html element source.
   * This will create an effect of removal of the image.
   */
  removePicture() {
    if (this.onChange) this.onChange(null);
    this.imageBase64.next(null);
  }

  /**
   * @description A callback function which should be implemented by the ControlValueAccessor interface.
   * Indicates if the form control is touched (influence also dirty and pristine).
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openScanovate() {
    if (this.enabled) {
      this.isScanovateOpen.next(true);
    }
  }

  onCancel() {
    this.isScanovateOpen.next(false);
  }

  onError() {
    this.isScanovateOpen.next(false);
    this.pendingError.emit();
  }

  /**
   * @description Recives image in base64 format string, and updates the subject behavior which connected to an img html element source.
   * This will create an effect of presentation of the image.
   * @param base64Image The converted image file base64 string.
   */
  private drawPicture(base64Image: string) {
    this.imageBase64.next(base64Image);
  }

  /**
   * @description this method resized the size of a blob according to given max height and weight.
   * basicity we will create a new instance of a given image but with the right size provided to us
   * by the component consumer.
   * ## Why all the fun?
   * in some cases the size of png file is to large to send to the server or to save in storage.
   * @param file
   * @returns a Blob object resized or not.
   */
  private resizeImage(imageBase64: string): Promise<string> {
    const maxWidth = this.maxWidth;
    const maxHeight = this.maxHeight;

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageBase64;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (width <= maxWidth && height <= maxHeight) {
          resolve(imageBase64);
        }

        let newWidth: number;
        let newHeight: number;
        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, newWidth, newHeight);
        imageBase64 = canvas.toDataURL('image/png');
        resolve(imageBase64);
      };

      image.onerror = reject;
    });
  }
}
