/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { LoaderStore } from './loader-store.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // Used to prevent case that multiple services running in parallel,
  // and the first one is finished before the others,
  // which will lead to loader disabling, although it still needs to be enabled.
  private _runningServicesCounter = 0;

  constructor(private loaderStore: LoaderStore) {}

  turnOff() {
    this._runningServicesCounter--;

    if (this._runningServicesCounter <= 0) {
      this._runningServicesCounter = 0;
      this.loaderStore.dispatchTurnOffLoader();
    }
  }
  turnOn() {
    this._runningServicesCounter++;

    if (this._runningServicesCounter) {
      this.loaderStore.dispatchTurnOnLoader();
    }
  }
}
