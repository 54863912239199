import { Injectable } from '@angular/core';
import { HttpContextService, httpResponseResult, HttpService } from '@cal2Deliver/core';
import { StorageHandler } from '@cal2Deliver/utils';
import { combineLatest, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { DefaultHttpContext, IAppHttpContext } from '../../../core/model/locals.interface';
import { CustomerAuthenticationStore } from '../../customer-authentication/services/customer-authentication-store';
import { ICourierDetails, IFormsData, IIdentificationViewModel } from '../model/identification.interface';
import { IdentificationStore } from './identification-store.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {
  /**
   * @description The forms get http request response size is very big, and therfore might take some time to load.
   * Due to this condition we let the user progress in the process while the data is loading.
   *
   * The formsHttpRequest property is used to check on the documents feature guard if the request is already loaded.
   * If it does the feature will mount, and if hasn't loaded yet, the feture's load event will await for the response to end,
   * and then continue to the feature.
   */
  private formsHttpRequest: Promise<IFormsData> = undefined;

  get courier$(): Observable<ICourierDetails> {
    return StorageHandler.fetchItem$<ICourierDetails>('courier');
  }

  constructor(
    private customerAuthStore: CustomerAuthenticationStore,
    private httpService: HttpService,
    private identificationStore: IdentificationStore
  ) {}

  /**
   * @description invoke get forms http call.
   * load collection of forms, belong to the customer so his autograph can be collected.
   * in our case, this http call takes relatively long time to respond so it
   * should be invoked behind the scene.
   */
  loadForms() {
    //delete old promise.
    this.formsHttpRequest = undefined;
    //create http context in order to not display the loader for a silent request.
    // const httpContext = { loader: false };

    const httpContext = HttpContextService.setContext<IAppHttpContext>({ omitLoader: true }, DefaultHttpContext);
    // retrieve a collection of form objects from the server, and save them in the store
    const formsHttpRequest$ = this.httpService.get<null, IFormsData>('forms', null, httpContext).pipe(
      catchError((error) => throwError(() => error)),
      take(1),
      //extract result property from the full calsale http response.
      httpResponseResult<IFormsData>(),
      tap((result) => {
        this.identificationStore.dispatchLoadFormsSuccess(result);
      })
    );

    // Saves the request promise on the current service
    this.formsHttpRequest = lastValueFrom(formsHttpRequest$);
  }

  get viewModel$(): Observable<Partial<IIdentificationViewModel>> {
    const customer$ = this.customerAuthStore.customerViewModel$;
    return combineLatest([this.courier$, customer$]).pipe(
      map(([courier, customer]) => {
        return {
          customerId: customer.id,
          customerFullName: customer.fullName,
          courierId: courier.courierId,
          courierFirstName: courier.firstName,
          courierLastName: courier.lastName
        };
      })
    );
  }

  resetIdentificationState() {
    this.identificationStore.dispatchResetState();
  }

  getFormsHttpRequest() {
    return this.formsHttpRequest;
  }
}
