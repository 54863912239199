import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.invalid) return null;

    const invalid = { length: { message: null } };

    if (!control.value) {
      return null;
    }
    const controlValLength = control.value.length;
    if (controlValLength == length) {
      return null;
    }
    return invalid;
  };
}
