import { Injectable } from '@angular/core';
import { HttpService } from '@cal2Deliver/core';
import { ISaveDocFileModel } from './models';

@Injectable({
  providedIn: 'root'
})
/**
 * @descrition Contains shared api for dealing with forms & docs (scane & signatures) sending to the server
 */
export class FileService {
  private get defaultFileModel(): Partial<ISaveDocFileModel> {
    return {
      data: null,
      docCode: null,
      fileID: 0,
      formCode: 0,
      formType: null,
      hash: null,
      isForm: false,
      pageHeight: 0,
      pageWidth: 0,
      signData: '',
      signature: {
        data: null,
        coordinates: null
      },
      signed: true,
      telecalDocCode: 0
    };
  }

  constructor(private httpService: HttpService) {}

  saveFiles(files: Partial<ISaveDocFileModel>[]) {
    const fullSaveFileModel: Partial<ISaveDocFileModel>[] = files.map((file) => ({
      ...this.defaultFileModel,
      ...file
    }));

    return this.httpService.post<Partial<ISaveDocFileModel>[], boolean>('saveFiles', fullSaveFileModel);
  }
}
