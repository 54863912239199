import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: true,
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseButtonComponent {
  abstract get hostClass(): string;

  @Input()
  disabled: boolean;

  @Input()
  type: 'button' | 'submit' = 'button';

  @Input()
  isCallToAction: boolean = false;
}
