import { HttpErrorResponse } from '@angular/common/http';
import { SERVER_UNAVAILABLE_STATUS_CODE, UNAUTHORIZED_STATUS_CODE } from '../models';
import { ApiError, BaseError, ClientError, NetworkError } from '../models/error';

/**functions builder for every error classes */
/**create the new instance with data extracted by the error param */

/**
 * @description creates or extract an HttpErrorResponse from an observable or a promise error.
 * @param error
 * @returns
 */
export function createHttpError(error: Error): HttpErrorResponse {
  if (!error) {
    return null;
  }
  const errorWithRejection: HttpErrorResponse = error['rejection'];
  if (errorWithRejection) {
    //means that the error came as a promise and we need to extract
    //the HttpErrorResponse from it's rejection property
    return errorWithRejection;
  }
  return error as HttpErrorResponse;
}
/**insatiate new  NetworkError*/
export function createNetworkError(error: Error): BaseError {
  if (!error) {
    return null;
  }
  const httpError = createHttpError(error);
  const statusCode = httpError.status;
  return new NetworkError(statusCode, 'network');
}
/**insatiate new  ClientError*/
export function createClientError(error: Error): BaseError {
  if (!error) {
    return null;
  }

  return new ClientError('client', '', '', error.message, error.stack, error.name, 'client');
}
/**
 *### create new  ApiError
 * @description an error can delivered to this methods in two shapes.
 * as an observable and need just to use it in order to create new ApiError instance.
 * or as a promise and than we will extract the HttpErrorResponse object via it's rejection property.
 * @param error
 * @returns new ApiError
 */
export function createApiError(error: Error): BaseError {
  if (!error) {
    return null;
  }
  const httpError = createHttpError(error);
  //extract all necessary properties in order to create new Api class instance
  const statusCode = httpError?.status;
  const status = httpError?.error?.status;
  if (status) {
    const { message, headlineMessage, description, messageCode } = status;
    const title = headlineMessage;
    return new ApiError(statusCode, 'api', title, message, description, messageCode);
  }
  if (statusCode === UNAUTHORIZED_STATUS_CODE) {
    return new ApiError(statusCode, 'api', 'נותקת מהמערכת', 'יש לבצע הזדהות מחדש', null, null);
  }
  //
  return new ApiError(statusCode, 'api', null, null, null, null);
}

/**
 ** @description decides what kind (type) of error provided via error parameter and instantiate new class of it.
 * we can achieve that by extracting the error properties.
 * for example, if the error is httpResponse error,we will check if the status code equals to zero.
 * if so, it is a no internet connection error therefore the error type is 'network'.
 * another example, we cannot determine the error as HttpErrorResponse it means that
 * it is a run time code error (ClientError class).
 * @param error
 */
export function resolveErrorType(error: Error | HttpErrorResponse): BaseError {
  const hasRejection = 'rejection' in error;
  const isHttpError = error instanceof HttpErrorResponse || hasRejection;
  if (!isHttpError) {
    return createClientError(error);
  }
  const httpError = createHttpError(error);
  //no internet connection or unavailable server.
  const isServerUnavailable = httpError?.status === SERVER_UNAVAILABLE_STATUS_CODE;

  const isNetworkError = !navigator.onLine || isServerUnavailable;
  if (isNetworkError) {
    return createNetworkError(error);
  }

  return createApiError(error);
}
