import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AssetContainerComponent, MiniFabButtonComponent } from '@cal2Deliver/ui';
import { toggleFadeAnimation } from '../../animations';
import { AuthLogoutService } from '../../services/auth-logout-service/auth-logout.service';
import { AppProgressComponent } from '../app-progress/app-progress.component';

@Component({
  standalone: true,
  selector: 'ctd-app-header',
  imports: [CommonModule, AssetContainerComponent, AppProgressComponent, MatIconModule, MiniFabButtonComponent],
  template: `
    <div class="header-image f-grow f-noshrink">
      <img src="./assets/svg/send.svg" width="100%" />
    </div>
    <div class="header-progress f-nogrow f-noshrink"><ctd-app-progress></ctd-app-progress></div>
    <div class="header-logo"><span>Cal</span><span>.</span><span>2Deliver</span></div>

    <div class="header-logout">
      <ctd-minifab-button class="btn btn--mini-fab btn--contrast" type="button" [@fadeInOut] (click)="logout()">
        <mat-icon alt="יציאה">exit_to_app</mat-icon>
      </ctd-minifab-button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleFadeAnimation]
})
export class CtdAppHeaderComponent {
  constructor(private authLogoutService: AuthLogoutService) {}
  @HostBinding('class')
  get hostClass() {
    return 'app-header';
  }
  logout() {
    this.authLogoutService.logout();
  }
}
