import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetContainerComponent, FlatButtonComponent, SignaturePadComponent } from '@cal2Deliver/ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ctd-signature-pad-dialog',
  standalone: true,
  imports: [CommonModule, SignaturePadComponent, FlatButtonComponent, ReactiveFormsModule, AssetContainerComponent],
  template: `
    <form [formGroup]="signatureForm">
      <ctd-container-asset
        path="./assets/svg/round-icon-doc-signaure.svg"
        class="icon-asset--rounded icon-asset--dialog-header icon-asset--lg"
      ></ctd-container-asset>
      <h1 mat-dialog-title>חתימה על מסמך "{{ data.formName }}"</h1>
      <div style="position: relative;" mat-dialog-content>
        <signature-pad
          formControlName="signature"
          [signaturePadConfig]="{ isSaveEnabled, minSignatureLengthToRatio: 1 }"
          (drawEnd)="submitForm()"
        ></signature-pad>
      </div>
      <div mat-dialog-actions>
        <ctd-flat-button class="btn btn--sm" type="button" (click)="closeForm()" mat-dialog-close>
          <span>סגור</span>
        </ctd-flat-button>
      </div>
    </form>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignaturePadDialogComponent implements OnDestroy {
  signatureForm: FormGroup<{
    signature: FormControl;
  }>;

  isSaveEnabled = false;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<unknown>,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.signatureForm = this.fb.group({
      signature: [null, [Validators.required]]
    });

    const subscription = this.signatureForm.statusChanges.subscribe(() => {
      this.isSaveEnabled = this.signatureForm.valid;
      this.changeDetector.detectChanges();
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitForm() {
    this.dialogRef.close({ base64Signature: this.signatureForm.controls.signature.value });
  }

  closeForm() {
    this.dialogRef.close();
  }
}
