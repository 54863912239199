/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { IFormsData } from '../model';

export class IdentificationActions {
  static reset = createAction('[Identification] reset');

  static loadSuccess = createAction('[Identification] Load Forms Success', props<{ formsData: IFormsData }>());
  static loadFailure = createAction('[Identification] Load Forms Failure', props<{ error: any }>());
}
