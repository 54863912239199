import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPdfFormModel, TPdfViewer } from '../../model';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DocumentItemComponent } from '../document-item';
import { FlatButtonComponent } from '@cal2Deliver/ui';

@Component({
  selector: 'ctd-document-gallery',
  standalone: true,
  imports: [CommonModule, DocumentItemComponent, ReactiveFormsModule, FlatButtonComponent],
  templateUrl: './document-gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DocumentGalleryComponent implements OnInit {
  @Input()
  forms: IPdfFormModel[];
  @Input()
  pdfFiles: TPdfViewer[];

  @Output()
  documentsApproved: EventEmitter<void> = new EventEmitter();

  documentsFormGroup: FormGroup<{
    signatures: FormArray<FormControl>;
  }>;
  formIndex: number;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formIndex = 0;

    const signaturesArray = this.forms.map(() => this.fb.control(null, [Validators.required]));

    this.documentsFormGroup = this.fb.group({
      signatures: this.fb.array(signaturesArray)
    });
  }

  onDocumentApproved(index: number) {
    if (this.documentsFormGroup.valid) this.documentsApproved.emit();
    else this.formIndex = index + 1;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
