import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { AuthenticationStore } from '../services/authentication-store.service';
import { AuthenticationService } from '../services/authentication.service';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { ILoginModel, IOtpData, IOtpModel } from '../model';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '../../../core/validators/custom-validators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Features } from '../../../config/features.config';
import { FlatButtonComponent } from '@cal2Deliver/ui';
import { FeatureConfigDirective, RegExpPatterns, toggleFadeAnimation } from '../../../core';
import { NgxMaskDirective } from 'ngx-mask';
import { environment } from '../../../../environments/environment';
import {
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaComponent
} from 'ng-recaptcha';
@Component({
  standalone: true,
  selector: 'ctd-authentication-page',
  imports: [
    CommonModule,
    EffectsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    NgxMaskDirective,
    MatInputModule,
    FlatButtonComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recapchaSiteKey } as RecaptchaSettings
    }
  ],
  templateUrl: './authentication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [FeatureConfigDirective],
  animations: [toggleFadeAnimation]
})
export class CtdAuthenticationComponent implements OnInit, AfterViewInit {
  @ViewChild('reCaptchaEl')
  reCaptchaEl: RecaptchaComponent;
  readonly feature = Features.get('authentication');

  readonly otpModel$: Observable<IOtpModel> = this.authStore.otpModel$;
  readonly loginModel$: Observable<ILoginModel> = this.authStore.loginModel$;
  readonly userPhone$: Observable<string> = this.authStore.userPhone$;
  readonly otpData$: Observable<IOtpData> = this.authStore.otpData$;
  readonly isRecapchaVisible$: Observable<boolean>;
  readonly isRecapchaEnabled: boolean = environment.isRecapchaEnabled;
  courierAuthFormGroup: FormGroup;
  private readonly isRecapchaVisibleEmitter = new BehaviorSubject<boolean>(true);
  constructor(
    private authStore: AuthenticationStore,
    private authService: AuthenticationService,
    private fb: FormBuilder
  ) {
    this.isRecapchaVisible$ = this.isRecapchaVisibleEmitter.asObservable();
  }
  ngAfterViewInit(): void {
    //Invoke the reCaptcha component
    this.reCaptchaEl?.execute();
  }

  ngOnInit(): void {
    this.courierAuthFormGroup = this.fb.group({
      couriorId: [
        { value: '', disabled: false },
        [CustomValidators.idValidator(), Validators.required, Validators.minLength(5), Validators.maxLength(9)]
      ],
      captchaToken: [{ value: null, disabled: false }, this.isRecapchaEnabled ? [Validators.required] : []],
      couriorPhoneNumber: [
        { value: '' },
        [CustomValidators.lengthValidator(10), Validators.required, Validators.pattern(RegExpPatterns.phoneNumber)]
      ],
      couriorPassword: [null, []]
    });
    this.authService.resetAuthenticationState();
  }

  /**
   * @description
   * Submit the auth form.
   * Assert submit method dynamically: if login data exist in store,
   * we invoke "sendOtp", otherwise - invoke the "login" method.
   */
  submitForm() {
    this.otpData$
      .pipe(
        take(1),
        // Used to handle case where the client closed the capcha accidentlly, and want to submit.
        // The switchMap pipe will cause the capcha being opened again in case the test failed,
        // wait to the capcha response, and then continue the submit process.
        switchMap((otpData: IOtpData) => {
          // Checks if the capcha field is valid.
          // In case capcha test didn't pass well, run the capcha test again on submit,
          // and then continue the submit process.
          if (!this.courierAuthFormGroup.get('captchaToken').valid) {
            this.reCaptchaEl?.execute();

            return this.reCaptchaEl?.resolved.pipe(map(() => otpData));
          }

          // In case capcha test passed well (the capcha field is valid), continue as usual
          return of(otpData);
        }),
        filter(() => this.courierAuthFormGroup.valid),
        tap(async (data: IOtpData) => {
          // extract values from form control.
          const { couriorId, couriorPassword, captchaToken, couriorPhoneNumber } = this.courierAuthFormGroup.value;
          if (data == undefined) {
            await this.authService.sendOtp({
              custIdNum: +couriorId,
              cellularPhoneNumber: couriorPhoneNumber,
              captchaToken
            });
            this.isRecapchaVisibleEmitter.next(false);
            this.courierAuthFormGroup.get('couriorId').disable();
            this.courierAuthFormGroup.get('couriorPhoneNumber').disable();
            this.courierAuthFormGroup
              .get('couriorPassword')
              .setValidators([Validators.required, CustomValidators.lengthValidator(6)]);
            this.courierAuthFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
          } else {
            await this.authService.sendLogin({ otpPassword: couriorPassword });
          }
        })
      )
      .subscribe();
  }
}
