/* eslint-disable @typescript-eslint/member-ordering */
import { createSelector } from '@ngrx/store';
import { ILoaderState } from './loader-state';

export class LoaderSelectors {
  static loaderState = (state): ILoaderState => {
    return state.loader;
  };

  static pending = createSelector(LoaderSelectors.loaderState, (state: ILoaderState): boolean => {
    return state.pending;
  });
}
