import { Injectable } from '@angular/core';
import { httpResponseResult, HttpService } from '@cal2Deliver/core';
import { EncryptionService } from '@cal2Deliver/utils';
import { TCalCalDocType } from '@calsale/scanovate';
import { environment } from '../../../../environments/environment';
import { catchError, lastValueFrom, map, Observable, tap, throwError, withLatestFrom } from 'rxjs';
import { FileService, ISaveScanFileModel } from '../../../core/services';
import { IDocsData, IDocumentData } from '../model';
import { ScanStoreService } from './scan-store.service';

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  get documents$(): Observable<(IDocumentData & { ocrDocType: TCalCalDocType | null })[]> {
    // Maps the ocr type to the document, by its docCode.
    // If Scanovate is not required for docCode, the ocr type will be null.
    return this.scanStore.scanovateErrorsCounter$.pipe(
      withLatestFrom(this.scanStore.documents$),
      map(([scanovateErrorsCounter, documents]) => {
        return documents.map((document) => {
          let ocrDocType: TCalCalDocType | null = null;

          const currentErrCounter = scanovateErrorsCounter[document.docCode];

          // Checks the number of scanovate errors that occured to the documents object by its docCode,
          // due to demand that after several errors of scanovate,
          // the user will be able to use the legacy (native) camera input component.
          if (
            environment.maxScannovateAttempts &&
            (!currentErrCounter || currentErrCounter < environment.maxScannovateAttempts)
          ) {
            if (this.isIdCertificate(document.docCode)) {
              ocrDocType = 'IL-ID';
            } else if (this.isDriverLicense(document.docCode)) {
              ocrDocType = 'IL-DL';
            }
          }

          return { ...document, ocrDocType };
        });
      })
    );
  }

  private get idCertificateDocCode(): number {
    return 1;
  }

  private get driverLicenseDocCode(): number {
    return 5;
  }

  constructor(
    private httpService: HttpService,
    private scanStore: ScanStoreService,
    private encryptionService: EncryptionService,
    private fileService: FileService
  ) {}

  async getDocs(): Promise<IDocsData> {
    this.scanStore.dispatchDocsData();

    const response$ = this.httpService.get<null, IDocsData>('docs').pipe(
      catchError((error) => {
        this.scanStore.dispatchDocsDataFailure(error);
        return throwError(() => error);
      }),
      httpResponseResult<IDocsData>(),
      tap((response) => this.scanStore.dispatchDocsDataSuccess(response))
    );

    return await lastValueFrom(response$);
  }

  /**
   * @description Receives documents data and base64 files,
   * creates an object which contains both document data and file data due to their correlation,
   * merges the object with the default file data,
   * converts the images from base64 to data url,
   * encrypts the data url,
   * and send it to the server.
   * The need to merge the file data with a default values of file data, is because the server expect those values.
   * @param documents A collection of docs metadata which require scanning.
   * @param formBase64Files The scanned streams of each document, as recieved from camera in base64 format.
   */
  async saveFiles(documents: IDocumentData[], formBase64Files: (string | string[])[]) {
    let saveFilesModel: Partial<ISaveScanFileModel>[] = documents.map((document, index) => {
      if (!document) {
        return null;
      }

      const { docCode, docID, hash } = document;

      return {
        signData: formBase64Files[index],
        docCode,
        docID,
        hash
      } as Partial<ISaveScanFileModel>;
    });

    saveFilesModel = this.flattenDocumentsArray(saveFilesModel)
      .map((file) => ({ ...file, signData: this.convertBase64ToDataUrl(file.signData) }))
      .map((file) => ({ ...file, signData: this.encryptionService.encrypt(file.signData, file.hash) }));

    const response$ = this.fileService.saveFiles(saveFilesModel).pipe(
      catchError((error) => {
        this.scanStore.dispatchSaveFilesFailure(error);
        return throwError(() => error);
      }),
      httpResponseResult<boolean>(),
      tap((response) => this.scanStore.dispatchSaveFilesSuccess(response))
    );

    return await lastValueFrom(response$);
  }

  /**
   * @description Dispatches the scanovate error counter value increment on the store.
   * @param docCode The docCode to increment its error value counter.
   */
  incrementScanovateErrorCounter(docCode: number) {
    this.scanStore.dispatchScanovateFailure(docCode);
  }

  isIdCertificate(docCode: number): boolean {
    return docCode === this.idCertificateDocCode;
  }

  isDriverLicense(docCode: number): boolean {
    return docCode === this.driverLicenseDocCode;
  }

  /**
   * @description Converts image base64 string to data url string.
   * @param base64 Input image base64 string.
   * @returns Input image as data url.
   */
  private convertBase64ToDataUrl(base64: string): string {
    if (!base64) return null;

    const seperator = ',';
    const seperatorIndex = base64.indexOf(seperator);

    return base64.substring(seperatorIndex + 1);
  }

  /**
   * @description Due to the possibility of scanovate to take more than 1 image,
   * for a single document item, we need to flatten the data before sending it to the server.
   * For that we duplicate the document object data, and in the image data we put in each object 1 image.
   * @param documents The documents array (both scanovate and non scanovate input images).
   * @returns Flatten documents array.
   */
  private flattenDocumentsArray(
    documents: (Partial<ISaveScanFileModel> | (Partial<ISaveScanFileModel> & { signData: string[] }))[]
  ): Partial<ISaveScanFileModel>[] {
    return documents.reduce((documents, doc) => {
      if ((doc.signData as any) instanceof Array) {
        const nestedDocs = doc.signData as string[];
        const flatDocs = nestedDocs.map((signData) => ({ ...doc, signData })) as Partial<ISaveScanFileModel>[];
        return [...documents, ...flatDocs];
      }

      return [...documents, doc as Partial<ISaveScanFileModel>];
    }, []);
  }
}
