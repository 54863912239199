import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { first, from, Observable } from 'rxjs';

export class CameraInputValidators {
  /**
   * @returns Async validator function that checks if the recieved base64 input is valid and can be loaded as an image.
   */
  static imageValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      const promise = new Promise((resolve) => {
        const { value } = control;

        if (!value) {
          return resolve(null);
        }

        const result = { image: null };

        const image = new Image();
        image.onload = () => {
          resolve(null);
        };
        image.onerror = () => {
          result.image = true;
          resolve(result);
        };

        image.src = value;
      });

      return from(promise).pipe(first());
    };
  }
}
