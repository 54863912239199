import { Component, HostBinding } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { routeAnimation } from './core/animations';
import { LoaderStore } from './core/loader';

@Component({
  selector: 'ctd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimation]
})
export class AppComponent {
  title = 'deliver';

  @HostBinding('class')
  get hostClass() {
    return 'app-root';
  }

  isLoaderOn$: Observable<boolean> = this.loaderStore.isOn$;

  constructor(public router: Router, private loaderStore: LoaderStore) {}

  routeState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
