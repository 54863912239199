import { Injectable, NgZone } from '@angular/core';
import {
  ApiError,
  BaseError,
  ClientError,
  ErrorService,
  IDialogConfig,
  NetworkError,
  RoutingService,
  UNAUTHORIZED_STATUS_CODE
} from '@cal2Deliver/core';
import { Features } from '../../../config';
import { DialogAdapter } from '../../../shared';

@Injectable({
  providedIn: 'root'
})
export class ErrorAdapter {
  constructor(
    private errorService: ErrorService,
    private routingService: RoutingService,
    private dialog: DialogAdapter,
    private ngZone: NgZone
  ) {}
  /**
   * @description one method to rule them all and in the darkness bind them
   *
   * implements all errors behavior here.
   * open popup, navigate to error page etc..
   * @param error
   */
  handleError(error: Error) {
    //determine error type and create new class of the relevant error
    //this error instance contains error messages, tile and more.
    const typedError = this.errorService.resolveError(error);
    switch (typedError.constructor) {
      case ApiError:
        this.handleApiError(typedError);
        break;
      case NetworkError:
        this.handleNetworkError(typedError);
        break;
      default:
        this.handleClientError(typedError);
        break;
    }
  }
  /**
   * @description handle all error thrown to us by http calls
   * @param error
   * @returns
   */
  private handleApiError(error: BaseError) {
    if (!error) {
      return;
    }
    const apiError = <ApiError>error;

    const options: IDialogConfig = {
      data: {
        title: error.title,
        message: error.description
      }
    };
    this.ngZone.run(() => this.dialog.showError(options));

    if (apiError.statusCode === UNAUTHORIZED_STATUS_CODE) {
      const route = Features.get('authentication')?.route;
      this.ngZone.run(() => this.routingService.moveTo(route));
    }
  }
  /**
   * @description handle all error thrown to us by code or run time
   * @param error
   * @returns
   */
  private handleClientError(error: BaseError) {
    if (!error) {
      return;
    }

    const clientError = <ClientError>error;
  }
  /**
   * @description handle no internet connection error
   * @param error
   * @returns
   */
  private handleNetworkError(error: BaseError) {
    if (!error) {
      return;
    }

    this.ngZone.run(() => this.dialog.showNetworkError());
  }
}
