import { Injectable } from '@angular/core';
import { HttpService, IHttpResponse } from '@cal2Deliver/core';
import { StorageHandler } from '@cal2Deliver/utils';
import { catchError, delay, lastValueFrom, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private httpService: HttpService) {}

  get orderToken$(): Observable<string> {
    return StorageHandler.fetchItem$<string>('orderToken');
  }

  /**
   * @description invoke isExistToken http call.
   * the sole purpose of this method is to check if our token,
   * in our case, orderToken is valid.
   * if the token is invalid we will not get a result equals false but the server will respond with 401 status code or so
   * and our error handler will navigate automatically to authentication page.
   * the actual token will be send by header.
   * @returns Promise<bool>
   *
   * todo: fix mock service to be able to handle both response from mock server and actual server.
   * real server returns just a bool value and mock returns full calsale http response.
   */
  validateToken$(): Observable<IHttpResponse<unknown>> {
    return this.httpService.post<null, IHttpResponse<unknown>>('isExistToken').pipe(
      catchError((error) =>
        throwError(() => {
          return error;
        })
      )
    );
  }
  validateToken(): Promise<IHttpResponse<unknown>> {
    return lastValueFrom(this.validateToken$());
  }
}
