import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RoutingService } from '@cal2Deliver/core';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { Features } from '../../config/features.config';
import { TokenService } from '../services/token-service/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenValidityGuard implements CanActivate {
  constructor(private routingService: RoutingService, private tokenService: TokenService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.tokenService.orderToken$.pipe(
      take(1),
      switchMap((token) => {
        if (token) {
          // read token from local storage, check if our saved token is valid or not.
          return this.tokenService.validateToken$() as unknown as Observable<boolean>;
        }
        // means that there is no token saved on any storage.
        return of(false);
      }),
      map((tokenValidity: boolean) => {
        if (tokenValidity) {
          return true;
        }
        // token is invalid, we will navigate to auth page.
        const { route } = Features.get('authentication');
        this.routingService.moveTo(route);
        return false;
      })
    );
  }
}
