import { importProvidersFrom, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Features } from './config/features.config';
import { AuthenticationEffects } from './features/authentication';
import { CustomerAuthenticationEffects } from './features/customer-authentication';
import { ScanGuard } from './features/scan/guards/scan.guard';
import { ScanEffects } from './features/scan/store';
import { AuthTokenValidityGuard } from './core/guards/auth-token-validity.guard';
import { AuthTokenPersistencyGuard } from './core/guards/auth-token-persistency.guard';
import { DocumentEffects } from './features/documents/store/documents-effects';
import { DocumentsGuard } from './features/documents/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'customer-authentication'
  },
  {
    path: Features.get('authentication')?.route,
    providers: [importProvidersFrom(EffectsModule.forFeature([AuthenticationEffects]))],
    loadComponent: () =>
      import('./features/authentication/page/authentication.component').then((mod) => mod.CtdAuthenticationComponent)
  },
  {
    path: Features.get('customer-authentication')?.route,
    providers: [importProvidersFrom(EffectsModule.forFeature([CustomerAuthenticationEffects]))],
    loadComponent: () =>
      import('./features/customer-authentication/page/customer-authentication.component').then(
        (mod) => mod.CtdCustomerAuthenticationComponent
      ),
    canActivate: [AuthTokenValidityGuard]
  },
  {
    path: Features.get('identification')?.route,
    loadComponent: () =>
      import('./features/identification/page/identification.component').then((mod) => mod.CtdIdentificationComponent),
    canActivate: [AuthTokenPersistencyGuard]
  },
  {
    path: Features.get('scan')?.route,
    providers: [importProvidersFrom(EffectsModule.forFeature([ScanEffects]))],
    loadComponent: () => import('./features/scan/page/scan.component').then((mod) => mod.CtdScanComponent),
    canActivate: [AuthTokenValidityGuard, ScanGuard]
  },
  {
    path: Features.get('documents')?.route,
    providers: [importProvidersFrom(EffectsModule.forFeature([DocumentEffects]))],
    loadComponent: () =>
      import('./features/documents/page/documents.component').then((mod) => mod.CtdDocumentsComponent),
    canActivate: [AuthTokenPersistencyGuard, DocumentsGuard]
  },
  {
    path: Features.get('approval')?.route,
    loadComponent: () => import('./features/approval/page/approval.component').then((mod) => mod.CtdApprovalComponent),
    canActivate: [AuthTokenPersistencyGuard]
  },

  {
    path: '**',
    loadComponent: () =>
      import('./features/authentication/page/authentication.component').then((mod) => mod.CtdAuthenticationComponent)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
