import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISaveDocFileModel } from '../../../core';
import { ICourierDetails } from '../../identification/model';
import { DocumentsActions } from '../store/documents-actions';
import { IDocumentState } from '../store/documents-state';

@Injectable({
  providedIn: 'root'
})
export class DocumentsStore {
  constructor(private store: Store<IDocumentState>) {}
  dispatchSaveFile(saveFileModel: Partial<ISaveDocFileModel>[]) {
    this.store.dispatch(DocumentsActions.saveFile({ saveFileModel }));
  }
  dispatchSaveFileSuccess(isFileDataSentSuccess: boolean) {
    this.store.dispatch(DocumentsActions.saveFileSuccess({ isFileDataSentSuccess }));
  }
  dispatchSaveFileFailure(error: any) {
    this.store.dispatch(DocumentsActions.saveFileFailure({ error }));
  }
  dispatchSubmitFile(submitFileModel: ICourierDetails) {
    this.store.dispatch(DocumentsActions.submitFile({ submitFileModel }));
  }
  dispatchSubmitFileSuccess(isSubmitFileDataSuccess: boolean) {
    this.store.dispatch(DocumentsActions.submitFileSuccess({ isSubmitFileDataSuccess }));
  }
  dispatchSubmitFileFailure(error: any) {
    this.store.dispatch(DocumentsActions.submitFileFailure({ error }));
  }
}
