import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EncryptionService } from './services';
import { ENCRYPTION_CONFIG } from './tokens';
import { IEncryptionConfig } from './models';

@NgModule({
  imports: [CommonModule]
})
export class EncryptionModule {
  static forRoot(encryptionConfig: IEncryptionConfig) {
    return {
      ngModule: EncryptionModule,
      providers: [
        EncryptionService,
        {
          provide: ENCRYPTION_CONFIG,
          useValue: encryptionConfig
        }
      ]
    };
  }
}
