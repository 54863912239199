/* eslint-disable @typescript-eslint/member-ordering */
import { createSelector } from '@ngrx/store';
import { ILoginData, ILoginModel, IOtpData, IOtpModel } from '../model';
import { IAuthenticationState } from './authentication-state';

export class AuthenticationSelectors {
  static authState = (state): IAuthenticationState => {
    return state.authentication;
  };
  static otpModel = createSelector(AuthenticationSelectors.authState, (authState: IAuthenticationState): IOtpModel => {
    return authState.otpModel;
  });

  static otpData = createSelector(AuthenticationSelectors.authState, (authState: IAuthenticationState): IOtpData => {
    return authState.otpData;
  });

  static loginModel = createSelector(
    AuthenticationSelectors.authState,
    (authState: IAuthenticationState): ILoginModel => {
      return authState.loginModel;
    }
  );

  static loginData = createSelector(
    AuthenticationSelectors.authState,
    (authState: IAuthenticationState): ILoginData => {
      return authState.loginData;
    }
  );
}
