/* eslint-disable @typescript-eslint/no-empty-interface */

import { ILoaderState } from '../../core/loader';

export type FeatureProgress = 'complete' | 'pristine' | 'active';

export interface IFeatureUiState {
  progress?: FeatureProgress;
  loader?: ILoaderState;
}

export interface IFeatureState {
  uiState?: IFeatureUiState;
}
