import { Injectable } from '@angular/core';
import { RoutingService } from '@cal2Deliver/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { Features } from '../../../config';
import { DocumentsActions } from './documents-actions';

@Injectable({
  providedIn: 'root'
})
export class DocumentEffects {
  submitFilesEffect$: Observable<Action> = createEffect(
    (): any =>
      this.actions$.pipe(
        ofType(DocumentsActions.submitFileSuccess),
        tap(() => {
          const route = Features.get('approval')?.route;
          this.routerService.moveTo(route);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private routerService: RoutingService) {}
}
