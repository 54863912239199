import { TCalScanConfig } from '@calsale/scanovate';
import { environment } from '../../environments/environment';

export const ScanovateConfig: TCalScanConfig = {
  serverUrl: environment.scanovateUrl,
  iframeFolder: '/demo',
  extraData: {
    appName: 'demo',
    proccessConfigName: 'mathilda.json',
    displayTextFileName: 'hebrew_client_config.json',
    token: ''
  }
};
