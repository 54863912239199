import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalScanService, ICalScanConfig, TCalCalDocType } from '@calsale/scanovate';
import { catchError, of, Subscription, tap } from 'rxjs';

@Component({
  selector: 'ctd-scanovate-camera',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scanovate-camera.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanovateCameraComponent implements AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  docType: TCalCalDocType;
  @Input()
  scanConfig: ICalScanConfig;

  @Output()
  pictureTaken: EventEmitter<string[]> = new EventEmitter();
  @Output()
  pendingCancel: EventEmitter<void> = new EventEmitter();
  @Output()
  pendingError: EventEmitter<void> = new EventEmitter();

  @ViewChild('scanovateContainer')
  scanovateContainer: ElementRef<HTMLDivElement>;

  constructor(private scanService: CalScanService, private renderer: Renderer2, private errorHandler: ErrorHandler) {}

  ngAfterViewInit(): void {
    this.loadScanovateIFrame();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * @description Creates a scanovate iFrame, due to the ocr type, renders it into the DOM, and listens to its events.
   */
  private loadScanovateIFrame() {
    const iframeUrl = this.createIFrameUrl(this.docType) as HTMLIFrameElement;

    iframeUrl.style.height = '100%';
    iframeUrl.style.width = '100%';

    if (iframeUrl) {
      this.renderer.appendChild(this.scanovateContainer.nativeElement, iframeUrl);

      const scanSubscription = this.scanService
        .listenToScanMessages({ responseType: 'data' })
        .pipe(
          tap((data) => {
            if (data.status !== 'success') {
              this.pendingError.emit();
              return;
            }

            // Maps scanovate images from simple dataURL to png based Base64 images.
            const imagesBase64 = data.stages.map(
              (stage) => 'data:image/png;base64,' + stage.payload.images.cropped_image
            );
            this.pictureTaken.emit(imagesBase64);
          }),
          catchError((err: { code: number }) => {
            // Error code 1105 means that the user clicked on the iFrame exit button,
            // and therefore we emit to the parent that cancel was requested.
            if (err.code === 1105) {
              this.pendingCancel.emit();
            } else {
              this.pendingError.emit();
            }

            return of();
          })
        )
        .subscribe();

      this.subscription.add(scanSubscription);
    }
  }

  /**
   * @description Creates a scanovate iFrame, due to the ocr type.
   */
  private createIFrameUrl(docType: TCalCalDocType) {
    let iframeUrl;

    switch (docType) {
      case 'IL-DL':
        iframeUrl = this.scanService.initializeScanOfDL(
          {
            resolveType: 'iframe'
          },
          this.scanConfig
        );
        break;

      case 'IL-ID':
        iframeUrl = this.scanService.initializeScanOfID(
          {
            resolveType: 'iframe'
          },
          this.scanConfig
        );
        break;

      default:
        break;
    }

    return iframeUrl;
  }
}
