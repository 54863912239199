/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  pairwise,
  raceWith,
  Subscription,
  switchMap,
  tap,
  throttleTime,
  throwError,
  withLatestFrom
} from 'rxjs';
import { ICoordinate, ISignaturePadConfig } from '../models/signature.interfaces';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { FlatButtonComponent, MiniFabButtonComponent, StrokedButtonComponent } from '../../buttons';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'signature-pad',
  standalone: true,
  imports: [CommonModule, FlatButtonComponent, StrokedButtonComponent, MiniFabButtonComponent, MatIconModule],
  template: `
    <div style="height: 100%; width: 100%">
      <div style="height: 100%; width: 100%">
        <canvas
          #signatureDrawerCanvas
          [width]="(offsetWidth$ | async) || signatureDrawerCanvas.scrollWidth"
          [height]="(offsetHeight$ | async) || signatureDrawerCanvas.scrollHeight"
        ></canvas>
      </div>
      <div style="display: flex; padding-top: 22px;">
        <div>
          <ctd-stroked-button
            class="btn btn--sm"
            [disabled]="!config.isSaveEnabled"
            type="button"
            (click)="onSignatureEnd()"
          >
            <!-- <mat-icon class="cloud-icon">cloud_queue</mat-icon> -->
            <span class="typo typo--sm typo--semibold">שמור</span>
          </ctd-stroked-button>
        </div>
        <div style="margin-right: 15px;">
          <ctd-minifab-button type="button" (click)="onClear()">
            <mat-icon class="remove-icon">delete</mat-icon>
          </ctd-minifab-button>
        </div>
      </div>
      <!--
      <div>
        <div class="submit-wrapper">
          <ctd-flat-button
            class="submit-button"
            color="primary"
            (click)="onSignatureEnd()"
            [disabled]="!config.isSaveEnabled"
            >Save</ctd-flat-button
          >
        </div>
        <div class="submit-wrapper">
          <ctd-stroked-button class="submit-button" (click)="onClear()">Delete</ctd-stroked-button>
        </div>
      </div> -->
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SignaturePadComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SignaturePadComponent),
      multi: true
    }
  ]
})
export class SignaturePadComponent implements OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  @Input()
  signaturePadConfig: Partial<ISignaturePadConfig>;

  /**
   * @description Emits an indication that signature drawing has ended.
   */
  @Output()
  drawEnd: EventEmitter<void> = new EventEmitter();

  @ViewChild('signatureDrawerCanvas')
  signatureCanvas: ElementRef<HTMLCanvasElement>;

  offsetWidth$: Observable<number>;
  offsetHeight$: Observable<number>;

  get config(): ISignaturePadConfig {
    return { ...this._defaultSignatureConfig, ...this.signaturePadConfig };
  }

  private offsetWidthEmitter: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private offsetHeightEmitter: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private signatureSubs = new Subscription();
  private isMouseDownOrTouchedEmitter = new BehaviorSubject<boolean>(false);
  private isDrawingInterrupted = new BehaviorSubject<boolean>(true);
  private onChange: (base64Signature: string) => void;
  private onTouched: () => void;

  /**
   * @desciption Represents the sum of signature lines pixels length.
   */
  private signatureSize: number;

  private readonly _defaultSignatureConfig: ISignaturePadConfig = {
    signatureColor: 'blue',
    signatureLineWidth: 3,
    isEditEnabled: true,
    resizeThrottle: 200,
    drawThrottle: 30,
    minSignatureLengthToRatio: 1,
    isSaveEnabled: true
  };

  constructor(@Inject(DOCUMENT) public document: Document) {
    this.offsetWidth$ = this.offsetWidthEmitter.asObservable();
    this.offsetHeight$ = this.offsetHeightEmitter.asObservable();
    /**
     * @description Sets the signature drawing pixel length to 0, because there is no singature.
     */
    this.signatureSize = 0;
  }

  ngAfterViewInit(): void {
    this.createOnDrawingStartPipe();
    this.createOnDrawingPipe();
    this.createOnDrawingEndPipe();
    this.createOnWindowResizePipe();
    this.createOnMouseOutPipe();
  }

  ngOnDestroy(): void {
    this.signatureSubs.unsubscribe();
  }

  /**
   * @description Called on registration to source form.
   * @param onChangeCallbackFunction A callback function which updates the source form on change of the form control value.
   */
  registerOnChange(onChangeCallbackFunction: (base64Signature: string) => void): void {
    this.onChange = onChangeCallbackFunction;
  }

  /**
   * @description The validation function for the component which implemented for the Validator interface.
   */
  validate(control: AbstractControl<string>): ValidationErrors {
    const { invalid, value } = control;

    if (!value || invalid) {
      return null;
    }

    const result = { signature: null };

    if (!this.isSignatureValid()) {
      result.signature = true;
      return result;
    }

    return null;
  }

  /**
   * @description A function that called on registration of the form control to the source form, with the initial external value of the form control from the source form.
   * Irrelevant due to the reason that signatures cannot be edited, and we start new signature every time.
   */
  writeValue(obj: string): void {}

  /**
   * @description A function that called on registration of the form control to the source form.
   * Signals to the form that the component has been touched.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onSignatureEnd() {
    if (this.config.isSaveEnabled) {
      this.drawEnd.emit();
    }
  }

  /**
   * @description Clears the signature canvas.
   */
  onClear() {
    this.signatureCanvas.nativeElement
      .getContext('2d')
      ?.clearRect(
        0,
        0,
        this.signatureCanvas.nativeElement.scrollWidth,
        this.signatureCanvas.nativeElement.scrollHeight
      );

    /**
     * @description Sets the signature drawing pixel length to 0, because signature is cleared.
     */
    this.signatureSize = 0;
    this.onChange && this.onChange(null);
  }

  /**
   * @descrition Creates an event listeners to mouse and touch start events on the signature canvas element.
   * On mouse or touch start event, the isMouseDownOrTouchedBehaviorSubject will be set to true, and drawing will be enabled.
   */
  private createOnDrawingStartPipe() {
    const touchStart$ = fromEvent(this.signatureCanvas.nativeElement, 'touchstart');
    const mouseDown$ = fromEvent(this.signatureCanvas.nativeElement, 'mousedown');

    const touchOrMouseStartSubscription = touchStart$
      .pipe(
        raceWith(mouseDown$),
        tap(() => {
          this.onTouched && this.onTouched();
          this.isMouseDownOrTouchedEmitter.next(true);
        })
      )
      .subscribe();

    this.signatureSubs.add(touchOrMouseStartSubscription);
  }

  /**
   * @descrition Creates an event listeners to mouse and touch move events on the signature canvas element.
   * On mouse or touch move event, the pipe will check if drawing is editable and started, and if it does, it will extract the drawing coordinate out of the event.
   * Then will check if the drawing was interrupted by exiting out of the canvas element.
   * After this the signature coordinate will be drawn to the canvas.
   */
  private createOnDrawingPipe() {
    const touchDrawing$ = fromEvent(this.signatureCanvas.nativeElement, 'touchmove');
    const mouseDrawing$ = fromEvent(this.signatureCanvas.nativeElement, 'mousemove');

    const drawingSubscription = touchDrawing$
      .pipe(
        raceWith(mouseDrawing$),
        tap((event) => event.preventDefault()),
        throttleTime(this.config.drawThrottle, null, { leading: true, trailing: true }),
        withLatestFrom(this.isMouseDownOrTouchedEmitter),
        filter(([_, isDrawing]) => isDrawing && this.config.isEditEnabled),
        switchMap(([event]) => this.getCoordinateFromEvent$(event)),
        pairwise(),
        withLatestFrom(this.isDrawingInterrupted),
        map(([[previousCoordinate, currentCoordinate], isDrawingInterrupted]) => {
          if (!isDrawingInterrupted) return [previousCoordinate, currentCoordinate];

          this.isDrawingInterrupted.next(false);
          return [currentCoordinate, currentCoordinate];
        }),
        tap(([previousCoordinate, currentCoordinate]) => {
          this.drawToCanvas(previousCoordinate, currentCoordinate);
          /**
           * @description Adds the drawn line pixel length to the total sum signature length.
           */
          this.signatureSize += this.calculateCoordinatesDistance(previousCoordinate, currentCoordinate);

          if (this.isOutOfCanvasBoundingBox(currentCoordinate)) {
            this.isMouseDownOrTouchedEmitter.next(false);
            this.isDrawingInterrupted.next(true);
            this.onChange && this.onChange(this.signatureAsDataUrl());
          }
        })
      )
      .subscribe();

    this.signatureSubs.add(drawingSubscription);
  }

  /**
   * @descrition Creates an event listeners to mouse and touch end events on the signature canvas element.
   * On mouse or touch end event, the isMouseDownOrTouchedBehaviorSubject will be set to false, and drawing will be disables.
   */
  private createOnDrawingEndPipe() {
    const touchEnd$ = fromEvent(this.signatureCanvas.nativeElement, 'touchend');
    const mouseUp$ = fromEvent(this.signatureCanvas.nativeElement, 'mouseup');

    const touchOrMouseEndSubscription = touchEnd$
      .pipe(
        raceWith(mouseUp$),
        tap(() => {
          this.isMouseDownOrTouchedEmitter.next(false);
          this.isDrawingInterrupted.next(true);
          this.onChange && this.onChange(this.signatureAsDataUrl());
        })
      )
      .subscribe();

    this.signatureSubs.add(touchOrMouseEndSubscription);
  }

  /**
   * @descrition Creates an event listeners to resize events on the window.
   * On resize of the window, the pipe will resize the canvas.
   */
  private createOnWindowResizePipe() {
    const windowResize$ = fromEvent(this.document.defaultView as Window, 'resize');

    const windowResizeSubscription = windowResize$
      .pipe(
        throttleTime(this.config.resizeThrottle, null, { leading: true, trailing: true }),
        tap(() => {
          this.offsetWidthEmitter.next(this.signatureCanvas.nativeElement.scrollWidth);
          this.offsetHeightEmitter.next(this.signatureCanvas.nativeElement.scrollHeight);
          /**
           * @description Sets the signature drawing pixel length to 0, because signature was cleared by the resize.
           */
          this.signatureSize = 0;
          /**
           * @description Updates the form control with the canvas value as data url, due to change of the signature in canvas by the resize.
           */
          this.onChange && this.onChange(null);
        })
      )
      .subscribe();

    this.signatureSubs.add(windowResizeSubscription);
  }

  /**
   * @descrition Creates an event listeners to mouse out event on the signature canvas element.
   * On mouse out, the drawing will be finished.
   * There is a problem, that when the mouse is out of the canvas element, it doesn't listen to the mouse up event (because the event is registered to the canvas, and the mouse is no longer above it).
   * The problem exists only on desktop, and therefore the function below implemented only for desktop to prevent the problem.
   */
  private createOnMouseOutPipe() {
    const mouseOut$ = fromEvent(this.signatureCanvas.nativeElement, 'mouseout');

    const mouseOutSubscription = mouseOut$
      .pipe(
        tap(() => {
          this.isMouseDownOrTouchedEmitter.next(false);
          this.isDrawingInterrupted.next(true);
          this.onChange && this.onChange(this.signatureAsDataUrl());
        })
      )
      .subscribe();

    this.signatureSubs.add(mouseOutSubscription);
  }

  /**
   * @description Extracts the coordinate out of mouse event.
   * @param event A mouse event.
   * @returns The extracted coordinate.
   */
  private getMousePosition(event: MouseEvent): ICoordinate {
    const x = event.clientX - this.signatureCanvas.nativeElement.getClientRects().item(0).x;
    const y = event.clientY - this.signatureCanvas.nativeElement.getClientRects().item(0).y;

    return { X: x, Y: y };
  }

  /**
   *@description Extracts the coordinate out of touch event.
   * @param event A touch event.
   * @returns The extracted coordinate.
   */
  private getTouchPosition(event: TouchEvent): ICoordinate {
    const x = event.changedTouches[0].clientX - this.signatureCanvas.nativeElement.getClientRects().item(0).x;
    const y = event.changedTouches[0].clientY - this.signatureCanvas.nativeElement.getClientRects().item(0).y;

    return { X: x, Y: y };
  }

  /**
   * @description Draws a line between 2 points on the signature canvas.
   * @param previousCoordinate The path begin coordinate.
   * @param currentCoordinate The path end coordinate.
   */
  private drawToCanvas(previousCoordinate: ICoordinate, currentCoordinate: ICoordinate) {
    const ctx = this.signatureCanvas.nativeElement.getContext('2d');
    const { signatureColor, signatureLineWidth } = this.config;

    ctx.beginPath();
    ctx.moveTo(previousCoordinate.X, previousCoordinate.Y);
    ctx.lineTo(currentCoordinate.X, currentCoordinate.Y);
    ctx.strokeStyle = signatureColor;
    ctx.lineWidth = signatureLineWidth;
    ctx.stroke();
    ctx.closePath();
  }

  /**
   * @description Receive an event, and checks if it is a valid mouse or touch event, and returns the coordinate out of it.
   * @param event A DOM event.
   * @returns If valid, returns observable of coordinate, else returns throwError observable.
   */
  private getCoordinateFromEvent$(event: Event) {
    if ((event as TouchEvent).changedTouches) return of(this.getTouchPosition(event as TouchEvent));
    else if (!isNaN((event as MouseEvent).clientX) && !isNaN((event as MouseEvent).clientY))
      return of(this.getMousePosition(event as MouseEvent));
    else return throwError(() => new Error("event is not valid, since it's not of type MouseEvent or TouchEvent"));
  }

  /**
   * @description Checks if the input coordinate is out of the signature canvas bounding box.
   * @param coordinate The coordinate that we check if is in valid canvas bounding box.
   * @returns Boolean which indicates if coordinate is out of the signature canvas bounding box.
   */
  private isOutOfCanvasBoundingBox(coordinate: ICoordinate) {
    return (
      coordinate.X < 0 ||
      coordinate.Y < 0 ||
      coordinate.X > this.signatureCanvas.nativeElement.offsetWidth ||
      coordinate.Y > this.signatureCanvas.nativeElement.offsetHeight
    );
  }

  /**
   * @description Checks if the signature valid by comparing the signature length sum to the canvas pixels ratio and the input minSignatureLengthToRatio value.
   */
  private isSignatureValid(): boolean {
    const padPixelsCount =
      this.signatureCanvas.nativeElement.scrollHeight * this.signatureCanvas.nativeElement.scrollWidth;

    return this.signatureSize > (padPixelsCount * this.config.minSignatureLengthToRatio) / 500;
  }

  /**
   * @description Calculates the pixel distance between 2 coordinates.
   * @param coordinate1 Path start coordinate.
   * @param coordinate1 Path End coordinate.
   * @return 2 input coordinates distance pixel length.
   */
  private calculateCoordinatesDistance(coordinate1: ICoordinate, coordinate2: ICoordinate): number {
    return Math.sqrt(Math.pow(coordinate1.X - coordinate2.X, 2) + Math.pow(coordinate1.Y - coordinate2.Y, 2));
  }

  /**
   * @description Converts the canvas to base64 string due to mime type from input.
   * @returns The canvas to base64 string.
   */
  private signatureAsDataUrl(): string {
    const base64Signature = this.signatureCanvas.nativeElement.toDataURL(null, 1.0);

    return base64Signature.replace('data:image/png;base64,', '');
  }
}
