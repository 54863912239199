import { Injectable } from '@angular/core';
import { createApiError, createClientError, createNetworkError } from '../functions';
import { TError } from '../models';
import { BaseError } from '../models/error';

@Injectable({
  providedIn: 'root'
})
export class ErrorConfigService {
  private readonly _errorsMap = new Map<TError, (error: Error) => BaseError>();
  /**
   * a Map utils class holds every error type by name and it's relevant error class builder
   */
  get errorsMap() {
    return this._errorsMap
      .set('api', createApiError)
      .set('client', createClientError)
      .set('network', createNetworkError);
  }
}
