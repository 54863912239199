import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, take, throwError } from 'rxjs';
import { TokenService } from '../services/token-service/token.service';

/**
  @description
  An HTTP interceptor which add an order token to request headers,
  if the token exists on store.
  The token header will contain the order token bearer + order token.
**/
@Injectable({
  providedIn: 'root'
})
export class OrderTokenInterceptor implements HttpInterceptor {
  orderTokenBearer = 'Order ';
  constructor(private tokenService: TokenService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const orderToken$ = this.tokenService?.orderToken$;

    return orderToken$?.pipe(
      take(1),
      catchError((err: unknown) => {
        return throwError(() => err);
      }),
      switchMap((orderToken: string) => {
        if (!orderToken) return next.handle(request);
        const requestClone: HttpRequest<unknown> = request.clone({
          headers: request.headers.append('orderauthorization', this.orderTokenBearer + orderToken)
        });

        return next.handle(requestClone);
      })
    );
  }
}
