import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ILoaderState, LoaderActions, LoaderSelectors } from '../store';

@Injectable({
  providedIn: 'root'
})
export class LoaderStore {
  readonly pending$: Observable<boolean> = this.store.select(LoaderSelectors.pending);

  get isOn$(): Observable<boolean> {
    return this.pending$.pipe(
      switchMap((pending: boolean) => {
        return of(pending);
      })
    );
  }
  get isOff$(): Observable<boolean> {
    return this.pending$.pipe(
      switchMap((pending: boolean) => {
        return of(!pending);
      })
    );
  }

  constructor(private store: Store<ILoaderState>) {}

  dispatchTurnOnLoader() {
    this.store.dispatch(LoaderActions.turnLoaderOn());
  }
  dispatchTurnOffLoader() {
    this.store.dispatch(LoaderActions.turnLoaderOff());
  }
}
