import { Action, ActionReducer, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { loaderReducerFn } from '../core/loader/store';
import { authenticationReducerFn } from '../features/authentication/store';
import { customerAuthenticationReducerFn } from '../features/customer-authentication/store';
import { identificationReducerFn } from '../features/identification/store';
import { scanReducerFn } from '../features/scan/store';
import { IRootState } from './root.state';

/** root state */
export const initialRootState: IRootState = {
  authentication: undefined,
  customerAuthentication: undefined,
  identification: undefined,
  scan: undefined,
  loader: undefined
};

export const rootMetaReducers: MetaReducer<IRootState>[] = [persistStateReducer, clearStateReducer];

/** root reducers union map */
export const featureReducers: ActionReducerMap<IRootState> = {
  authentication: authenticationReducerFn,
  customerAuthentication: customerAuthenticationReducerFn,
  identification: identificationReducerFn,
  scan: scanReducerFn,
  loader: loaderReducerFn
};

export function persistStateReducer(reducer: ActionReducer<IRootState>) {
  const storageKey = 'cal2deliver';
  return (state: IRootState | undefined, action: Action) => {
    // on state initialization the state is undefined, we want to retrieve the state from local storage now
    if (state === undefined) {
      const persisted = sessionStorage.getItem(storageKey);
      // if we got something from local storage - return it, otherwise - use the reducer to instantiate the state
      return persisted ? JSON.parse(persisted) : reducer(state, action);
    }

    // on every action we call the "normal" reducer
    const newState = reducer(state, action);
    // update local storage with the new state

    sessionStorage.setItem(storageKey, JSON.stringify(newState));
    return newState;
  };
}

export function clearStateReducer(reducer) {
  return function (state: IRootState, action: Action) {
    if (action.type === '[Root] Reset State') {
      state = initialRootState;
    }

    const nextState = reducer(state, action);

    return nextState;
  };
}
