import { createReducer, on } from '@ngrx/store';
import { ScanActions } from './scan-actions';
import { IScanState } from './scan-state';

export const initialScanState: IScanState = {
  documentsData: undefined,
  saveFilesModel: undefined,
  scanovateErrorCounter: {}
};

export const scanReducerFn = createReducer(
  initialScanState,
  on(ScanActions.reset, (state: IScanState, action) => {
    return { ...initialScanState };
  }),
  on(ScanActions.loadDocuments, (state: IScanState, action) => {
    return { ...state };
  }),
  on(ScanActions.loadDocumentsSuccess, (state: IScanState, action) => {
    const { docsData } = action;
    return {
      ...state,
      documentsData: docsData
    };
  }),
  on(ScanActions.loadDocumentsFailure, (state: IScanState, action) => {
    const { err } = action;
    return {
      ...state,
      ...{ error: err }
    };
  }),
  on(ScanActions.saveFiles, (state: IScanState, action) => {
    const { saveFilesModel } = action;
    return {
      ...state,
      saveFilesModel
    };
  }),
  on(ScanActions.saveFilesSuccess, (state: IScanState, action) => {
    const { saveFilesResponse } = action;
    return {
      ...state,
      ...{ success: saveFilesResponse }
    };
  }),
  on(ScanActions.saveFilesFailure, (state: IScanState, action) => {
    const { err } = action;
    return {
      ...state,
      ...{ error: err }
    };
  }),
  on(ScanActions.scanovateFailure, (state: IScanState, action) => {
    const { docCode } = action;
    return {
      ...state,
      ...{
        scanovateErrorCounter: {
          ...state.scanovateErrorCounter,
          [docCode]: state.scanovateErrorCounter[docCode] !== undefined ? state.scanovateErrorCounter[docCode] + 1 : 1
        }
      }
    };
  })
);
