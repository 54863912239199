import { HttpContext, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP_CONTEXT } from '../models';

@Injectable({
  providedIn: 'root'
})
export class HttpContextService {
  static setContext<T>(context: T, defaultContext: T): HttpContext {
    const httpContext = new HttpContext();
    const customContext = Object.assign({}, { ...defaultContext }, { ...context });
    httpContext.set<T>(HTTP_CONTEXT, customContext);
    return httpContext;
  }

  static getContext<T>(request: HttpRequest<any>): T {
    return request.context.get(HTTP_CONTEXT);
  }
}
