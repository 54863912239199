import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'ctd-stroked-button',
  standalone: true,
  imports: [CommonModule, MatRippleModule /*, MatButtonModule */],
  template: `
    <button [type]="type" role="type">
      <ng-content></ng-content>
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrokedButtonComponent extends BaseButtonComponent {
  @HostBinding('class')
  get hostClass() {
    const baseClass = `btn btn--stroked`;
    if (this.disabled) {
      return `${baseClass} btn--disabled`;
    }
    return baseClass;
  }

  constructor() {
    super();
  }
}
