/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, HostBinding, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { TResponsiveSize } from '../../core/ui.interface';

@Component({
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  selector: 'ctd-container-asset',
  template: ` <img [src]="path" style="width:100%;max-height:100%" /> `
})
export class AssetContainerComponent {
  @Input()
  path: string;

  @Input()
  size: TResponsiveSize | string;

  @Input()
  isResponsive: boolean = true;

  @Input()
  fill: boolean;

  @Input()
  width: string;

  @Input()
  alt: string;

  @Input()
  inheritParentSize: boolean = true;

  @HostBinding('style')
  get fillStyle() {
    if (this.isResponsive) {
      return `width: 100%; max-height: 100%`;
    }

    return `width: inherit; height: fillStyle`;
  }

  constructor(private sanitizer: DomSanitizer) {}
}
