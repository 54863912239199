<div class="feature-config">
  <div class="feature-title"><span class="emph pad-lt-sm">זיהוי</span><span class="light">שליח</span></div>

  <!-- <ctd-flat-button class="btn btn--sm" type="button" (click)="showError()">
    <span>show error</span>
  </ctd-flat-button>

  <ctd-flat-button class="btn btn--sm" type="button" (click)="showConfirm()">
    <span>show confirm</span>
  </ctd-flat-button> -->

  <form [formGroup]="courierAuthFormGroup" #courierAuthForm="ngForm" (submit)="submitForm()">
    <div class="form-control">
      <mat-form-field>
        <mat-label>תעודת זהות </mat-label>
        <input type="tel" matInput formControlName="couriorId" #couriorId />

        <mat-hint>כפי שכתוב בת"ז שלך</mat-hint>

        <mat-error
          role="alert"
          *ngIf="courierAuthFormGroup.get('couriorId').hasError('required') && courierAuthForm.submitted"
          >יש להזין את מספר תעודת הזהות</mat-error
        >
        <mat-error role="alert" *ngIf="courierAuthFormGroup.get('couriorId').hasError('idNumber')"
          >ת.ז שהוזנה אינה תקינה</mat-error
        >
        <mat-error role="alert" *ngIf="courierAuthFormGroup.get('couriorId').hasError('minlength')"
          >יש להזין לפחות 5 ספרות</mat-error
        >
        <mat-error role="alert" *ngIf="courierAuthFormGroup.get('couriorId').hasError('maxlength')"
          >יש להזין עד 9 ספרות</mat-error
        >
      </mat-form-field>
    </div>
    <div class="form-control">
      <mat-form-field>
        <mat-label>מספר טלפון </mat-label>
        <input type="tel" mask="000-0000000" matInput formControlName="couriorPhoneNumber" #couriorPhoneNumber />

        <mat-hint>מספר טלפון נייד בן 10 ספרות</mat-hint>

        <mat-error
          role="alert"
          *ngIf="!courierAuthFormGroup.get('couriorPhoneNumber').valid && courierAuthForm.submitted"
          >יש להזין מספר טלפון</mat-error
        >

        <mat-error role="alert" *ngIf="courierAuthFormGroup.get('couriorPhoneNumber').hasError('maxlength')"
          >יש להזין עד 10 ספרות</mat-error
        >
      </mat-form-field>
    </div>
    <div class="form-control" *ngIf="isRecapchaEnabled && (isRecapchaVisible$ | async)">
      <re-captcha #reCaptchaEl size="invisible" formControlName="captchaToken"></re-captcha>
    </div>
    <ng-container *ngIf="otpData$ | async">
      <div class="form-control" [@fadeInOut]>
        <mat-form-field>
          <mat-label>סיסמה חד-פעמית</mat-label>

          <input type="tel" matInput formControlName="couriorPassword" #couriorPassword />

          <mat-hint *ngIf="userPhone$ | async as phone"
            >נשלחה הודעה לטלפון: <span style="display: inline-block; direction: ltr">{{ phone }}</span></mat-hint
          >

          <mat-error
            role="alert"
            *ngIf="
              courierAuthFormGroup.get('couriorPassword').hasError('required') &&
              courierAuthFormGroup.get('couriorPassword').touched
            "
            >יש להזין סיסמה חד פעמית</mat-error
          >

          <mat-error role="alert" *ngIf="courierAuthFormGroup.get('couriorPassword').hasError('length')"
            >יש להזין 6 ספרות</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <div class="submit-wrapper">
      <!--  -->
      <ctd-flat-button type="submit" class="submit-button">
        <span>המשך</span>
      </ctd-flat-button>
    </div>
  </form>
</div>
