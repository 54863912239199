import { createAction, props } from '@ngrx/store';
import { ISaveScanFileModel } from '../../../core/services';
import { IDocsData } from '../model';

export class ScanActions {
  static reset = createAction('[scan] reset');

  static loadDocuments = createAction('[scan] Load documents');
  static loadDocumentsSuccess = createAction('[scan] Load documents Success', props<{ docsData: IDocsData }>());
  static loadDocumentsFailure = createAction('[scan] Load documents Failure', props<{ err: any }>());

  static saveFiles = createAction('[scan] Save files', props<{ saveFilesModel: Partial<ISaveScanFileModel>[] }>());
  static saveFilesSuccess = createAction('[scan] Save files Success', props<{ saveFilesResponse: boolean }>());
  static saveFilesFailure = createAction('[scan] Save files Failure', props<{ err: any }>());

  static scanovateFailure = createAction('[scan] Scanovate failure', props<{ docCode: number }>());
}
