import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, switchMap, take, withLatestFrom } from 'rxjs';
import {
  ICustomerOtpCheckData,
  ICustomerOtpCheckModel,
  ICustomerOtpData,
  ICustomerOtpModel,
  ICustomerViewModel
} from '../model';
import { CustomerAuthenticationActions, CustomerAuthenticationSelectors, ICustomerAuthenticationState } from '../store';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthenticationStore {
  readonly otpCheckModel$: Observable<ICustomerOtpCheckModel> = this.store.select(
    CustomerAuthenticationSelectors.otpCheckModel
  );
  readonly otpCheckData$: Observable<ICustomerOtpCheckData> = this.store.select(
    CustomerAuthenticationSelectors.otpCheckData
  );
  readonly otpModel$: Observable<ICustomerOtpModel> = this.store.select(CustomerAuthenticationSelectors.otpModel);
  readonly otpData$: Observable<ICustomerOtpData> = this.store.select(CustomerAuthenticationSelectors.otpData);

  get peekCustomerId$(): Observable<number> {
    return this.otpCheckModel$.pipe(
      take(1),
      map((model: ICustomerOtpCheckModel) => {
        return model.custIdNum;
      })
    );
  }
  get customerFullName$(): Observable<string> {
    return combineLatest([this.otpData$, this.otpCheckData$]).pipe(
      map(([otpData, otpCheckData]) => {
        //in case the customer process does not need otp, we will take his name from otpData (first http call response)
        //else (otp is required) we will take it from otpCheckData (second http call response).
        const firstName = otpData?.fisrtName || otpCheckData?.firstName;
        const lastName = otpData?.lastName || otpCheckData?.lastName;
        //
        return `${firstName} ${lastName}`;
      })
    );
  }

  get customerViewModel$(): Observable<ICustomerViewModel> {
    const id$ = this.peekCustomerId$;
    const fullName$ = this.customerFullName$;

    return combineLatest([id$, fullName$]).pipe(map(([id, fullName]) => ({ id, fullName })));
  }
  get isOtpRequired$(): Observable<boolean> {
    return this.otpCheckData$.pipe(
      map((data: ICustomerOtpCheckData) => {
        return !!data?.isOtpRequired;
      })
    );
  }

  /**
   * @description token's value will be determined by isOtpRequired value.
   * if the customer needs otp we will the digitalDeliveryToken property from otpCheckData http response.
   * else will be taken from otpData http response token property.
   */
  get otpToken(): Observable<string> {
    return this.otpCheckData$.pipe(
      withLatestFrom(this.otpData$),
      switchMap(([otpData, otpCheckData]) => {
        if (otpData?.isOtpRequired) {
          return of(otpCheckData?.digitalDeliveryToken);
        }
        return of(otpData?.token);
      })
    );
  }
  get customerPhone$(): Observable<string> {
    return this.otpCheckData$.pipe(map((data: ICustomerOtpCheckData) => data?.phone));
  }

  constructor(private store: Store<ICustomerAuthenticationState>) {}

  dispatchResetState() {
    this.store.dispatch(CustomerAuthenticationActions.reset());
  }
  dispatchSubmitOtpCheck(otpCheckModel: ICustomerOtpCheckModel) {
    this.store.dispatch(CustomerAuthenticationActions.otpCheckSubmit({ otpCheckModel }));
  }

  dispatchSubmitOtpCheckSuccess(otpCheckData: ICustomerOtpCheckData) {
    this.store.dispatch(CustomerAuthenticationActions.otpCheckSubmitSuccess({ otpCheckData }));
  }

  dispatchSubmitOtpCheckFailure(error: any) {
    this.store.dispatch(CustomerAuthenticationActions.otpCheckSubmitFailure({ error }));
  }

  dispatchSubmitOtp(otpModel: ICustomerOtpModel) {
    this.store.dispatch(CustomerAuthenticationActions.otpSubmit({ otpModel }));
  }
  dispatchSubmitOtpSuccess(otpData: ICustomerOtpData) {
    this.store.dispatch(CustomerAuthenticationActions.otpSubmitSuccess({ otpData }));
  }

  dispatchSubmitOtpFailure(error: any) {
    this.store.dispatch(CustomerAuthenticationActions.otpSubmitFailure({ error }));
  }
}
