<div class="pdf-viewer-container" #pdfViewerContainer>
  <ng-container *ngIf="config$ | async as config">
    <ng-container *ngIf="config.pages?.length">
      <ng-container *ngFor="let page of config.pages">
        <div class="pdf-page-conatiner">
          <img class="pdf-page-content" [src]="page.pageContentDataUrl" [ngStyle]="{
              height: page.height + 'px',
              width: page.width + 'px'
            }" />
          <ng-container *ngIf="config.base64Signature">
            <ng-container *ngFor="let signature of page.signatures">
              <img (click)="onSigningRequest()" class="pdf-page-signature" [src]="config.base64Signature" [ngStyle]="{
                  height: signature.height + 'px',
                  width: signature.width + 'px',
                  bottom: signature.bottom + 'px',
                  left: signature.left + 'px'
                }" />
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!config.base64Signature && page.signatures">
            <ng-container *ngFor="let signature of page.signatures">
              <button (click)="onSigningRequest()" class="pdf-page-signature-sign-placeholder" [ngStyle]="{
                  height: signature.height + 'px',
                  width: signature.width + 'px',
                  bottom: signature.bottom + 'px',
                  left: signature.left + 'px'
                }">
                <span>
                  לחץ לחתימה
                </span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>