import { Action, createReducer, on } from '@ngrx/store';
import { IdentificationActions } from './identification-actions';
import { IIdentificationState } from './identification-state';

export const initialIdentificationState: IIdentificationState = {
  formsData: undefined
};

export const identificationReducerFn = createReducer(
  initialIdentificationState,
  on(IdentificationActions.reset, () => {
    return {
      ...initialIdentificationState
    };
  }),

  on(IdentificationActions.loadSuccess, (state: IIdentificationState, action) => {
    const { formsData } = action;
    return {
      ...state,
      ...{ formsData }
    };
  }),
  on(IdentificationActions.loadFailure, (state: IIdentificationState, action) => {
    const { error } = action;
    return {
      ...state,
      ...{
        ...{ error: error }
      }
    };
  })
);

export function identificationReducer(state: IIdentificationState | undefined, action: Action) {
  return identificationReducerFn(state, action);
}
