<div class="feature-config">
  <div class="feature-title"><span class="emph pad-lt-sm">זיהוי</span><span class="light">לקוח</span></div>

  <form [formGroup]="authFormGroup" #otpForm="ngForm" (submit)="submitForm()">
    <div class="form-control">
      <mat-form-field>
        <mat-label>ת.ז לקוח</mat-label>
        <input type="tel" matInput formControlName="custIdNum" #custIdNum />
        <mat-error *ngIf="authFormGroup.get('custIdNum').hasError('required') && otpForm.submitted" role="alert"
          >יש להזין את מספר תעודת הזהות של הלקוח</mat-error
        >
        <mat-error *ngIf="authFormGroup.get('custIdNum').hasError('idNumber')" role="alert"
          >ת.ז שהוזנה אינה תקינה</mat-error
        >
        <mat-error *ngIf="authFormGroup.get('custIdNum').hasError('minlength')" role="alert"
          >יש להזין לפחות 5 ספרות</mat-error
        >
        <mat-error *ngIf="authFormGroup.get('custIdNum').hasError('maxlength')" role="alert"
          >יש להזין עד 9 ספרות</mat-error
        >
      </mat-form-field>
    </div>

    <div class="form-control">
      <mat-form-field>
        <mat-label>ברקוד שליחות</mat-label>
        <input type="tel" matInput formControlName="deliveryBarcode" #deliveryBarcode />
        <mat-error *ngIf="authFormGroup.get('deliveryBarcode').hasError('required') && otpForm.submitted" role="alert"
          >יש להזין ברקוד שליחות</mat-error
        >
        <mat-error *ngIf="authFormGroup.get('deliveryBarcode').hasError('minlength')" role="alert"
          >יש להזין לפחות 5 ספרות</mat-error
        >
        <mat-error *ngIf="authFormGroup.get('deliveryBarcode').hasError('maxlength')" role="alert"
          >יש להזין עד 13 ספרות</mat-error
        >
      </mat-form-field>
    </div>

    <ng-container *ngIf="isOtpRequired$ | async">
      <div class="form-control" [@fadeInOut]>
        <mat-form-field>
          <mat-label>סיסמה חד-פעמית</mat-label>
          <input type="tel" matInput formControlName="otpPassword" #otpPassword />

          <mat-hint *ngIf="customerPhone$ | async as phone"
            >נשלחה הודעה לטלפון הלקוח למספר: <span class="nowrap">{{ phone }}</span></mat-hint
          >
          <mat-error
            *ngIf="authFormGroup.get('otpPassword').hasError('required') && authFormGroup.get('otpPassword').touched"
            role="alert"
            >יש להזין סיסמה חד פעמית</mat-error
          >
          <mat-error *ngIf="authFormGroup.get('otpPassword').hasError('length')" role="alert"
            >יש להזין 6 ספרות</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <div class="submit-wrapper">
      <ctd-flat-button type="submit" class="submit-button">
        <span>המשך</span>
      </ctd-flat-button>
    </div>
  </form>
</div>
