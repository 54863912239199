import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetContainerComponent, FlatButtonComponent, StrokedButtonComponent } from '@cal2Deliver/ui';
import { IConfirmDialog } from '@cal2Deliver/core';
@Component({
  standalone: true,
  selector: 'ctd-confirm-dialog',
  imports: [CommonModule, FlatButtonComponent, StrokedButtonComponent, AssetContainerComponent],
  template: ` <ctd-container-asset
      path="./assets/svg/round-icon-document-alert.svg"
      class="icon-asset--rounded icon-asset--dialog-header icon-asset--lg"
    ></ctd-container-asset>
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <ctd-stroked-button class="btn btn--sm" type="button" mat-dialog-close (click)="close(false)">
        <span>ביטול</span>
      </ctd-stroked-button>

      <ctd-flat-button class="btn btn--sm" type="button" mat-dialog-close (click)="close(true)" cdkFocusInitial>
        <span>אישור</span>
      </ctd-flat-button>
    </div>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmDialog, private dialogRef: MatDialogRef<unknown>) {}
  close(confirm: boolean) {
    this.dialogRef.close({ confirm });
  }
}
