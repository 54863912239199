import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { IOtpModel, IOtpData, ILoginModel, ILoginData, ICourierViewModel } from '../model';
import { AuthenticationActions, AuthenticationSelectors, IAuthenticationState } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStore {
  readonly otpModel$: Observable<IOtpModel> = this.store.select(AuthenticationSelectors.otpModel);
  readonly otpData$: Observable<IOtpData> = this.store.select(AuthenticationSelectors.otpData);

  readonly loginModel$: Observable<ILoginModel> = this.store.select(AuthenticationSelectors.loginModel);
  readonly loginData$: Observable<ILoginData> = this.store.select(AuthenticationSelectors.loginData);

  get courierId$(): Observable<number> {
    return this.otpModel$.pipe(
      switchMap((model: IOtpModel) => {
        return of(model.custIdNum);
      })
    );
  }

  get courierFullName$(): Observable<string> {
    return this.loginData$.pipe(
      switchMap((data: ILoginData) => {
        return of(`${data.fisrtName} ${data.lastName}`);
      })
    );
  }
  get courierViewModel$(): Observable<ICourierViewModel> {
    const id$ = this.courierId$;
    const fullName$ = this.courierFullName$;

    return combineLatest([id$, fullName$]).pipe(map(([id, fullName]) => ({ id, fullName })));
  }
  get isOtpRequired$(): Observable<boolean> {
    return this.otpData$.pipe(map((data) => !!data?.isOtpRequired));
  }
  get isCourierOtpVerified$(): Observable<boolean> {
    return this.otpData$.pipe(
      switchMap((data: IOtpData) => {
        return of(!!data?.token) as Observable<boolean>;
      })
    );
  }
  get deliveryToken$(): Observable<string> {
    return this.otpData$.pipe(map((data) => data?.digitalDeliveryToken));
  }
  get userPhone$(): Observable<string> {
    return this.otpData$.pipe(map((data) => data?.phone));
  }
  constructor(private store: Store<IAuthenticationState>) {}

  dispatchResetAuthenticationState() {
    this.store.dispatch(AuthenticationActions.reset());
  }

  dispatchSubmitOtp(otpModel: IOtpModel) {
    this.store.dispatch(AuthenticationActions.otpSubmit({ otpModel }));
  }
  dispatchSubmitOtpSuccess(otpData: IOtpData) {
    this.store.dispatch(AuthenticationActions.otpSubmitSuccess({ otpData }));
  }
  dispatchSubmitOtpFailure(error: any) {
    this.store.dispatch(AuthenticationActions.otpSubmitFailure({ error }));
  }
  dispatchLogin(loginModel: ILoginModel) {
    this.store.dispatch(AuthenticationActions.loginSubmit({ loginModel }));
  }
  dispatchLoginSuccess(loginData: ILoginData) {
    this.store.dispatch(AuthenticationActions.loginSubmitSuccess({ loginData }));
  }
  dispatchLoginFailure(error: any) {
    this.store.dispatch(AuthenticationActions.loginSubmitFailure({ error }));
  }
}
