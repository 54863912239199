/* eslint-disable @typescript-eslint/member-ordering */
import { createSelector } from '@ngrx/store';
import { IFormsData } from '../model';
import { IIdentificationState } from './identification-state';

export class IdentificationSelectors {
  static identificationState = (state): IIdentificationState => {
    return state.identification;
  };

  static formsData = createSelector(
    IdentificationSelectors.identificationState,
    (state: IIdentificationState): IFormsData => {
      return state.formsData;
    }
  );
}
