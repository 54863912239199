import { Action, createReducer, on } from '@ngrx/store';
import { CustomerAuthenticationActions } from './customer-authentication-actions';
import { ICustomerAuthenticationState } from './customer-authentication-state';

export const initialCustomerAuthenticationState: ICustomerAuthenticationState = {
  otpCheckModel: undefined,
  otpCheckData: undefined,
  otpModel: undefined,
  otpData: undefined
};

export const customerAuthenticationReducerFn = createReducer(
  initialCustomerAuthenticationState,
  on(CustomerAuthenticationActions.reset, () => {
    return {
      ...initialCustomerAuthenticationState
    };
  }),
  on(CustomerAuthenticationActions.otpCheckSubmit, (state: ICustomerAuthenticationState, action) => {
    const { otpCheckModel } = action;

    return {
      ...state,
      ...{
        otpCheckModel
      }
    };
  }),
  on(CustomerAuthenticationActions.otpCheckSubmitSuccess, (state: ICustomerAuthenticationState, action) => {
    const { otpCheckData } = action;

    return {
      ...state,
      ...{
        otpCheckData
      }
    };
  }),
  on(CustomerAuthenticationActions.otpCheckSubmitFailure, (state: ICustomerAuthenticationState, action) => {
    const { error } = action;
    return {
      ...state,
      ...{
        ...{ error: error }
      }
    };
  }),
  on(CustomerAuthenticationActions.otpSubmit, (state: ICustomerAuthenticationState, action) => {
    const { otpModel } = action;
    return {
      ...state,
      ...{
        otpModel
      }
    };
  }),
  on(CustomerAuthenticationActions.otpSubmitSuccess, (state: ICustomerAuthenticationState, action) => {
    const { otpData } = action;

    return {
      ...state,
      ...{
        otpData
      }
    };
  }),
  on(CustomerAuthenticationActions.otpSubmitFailure, (state: ICustomerAuthenticationState, action) => {
    const { error } = action;
    return {
      ...state,
      ...{
        ...{ error: error }
      }
    };
  })
);

export function customerAuthenticationReducer(state: ICustomerAuthenticationState | undefined, action: Action) {
  return customerAuthenticationReducerFn(state, action);
}
