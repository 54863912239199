import { Injectable, Inject, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogConfig, IDialogGlobalConfig } from '../models/dialog.interface';
import { CAL_DIALOG_GLOBAL_CONFIG } from '../token/dialog.token';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    @Inject(CAL_DIALOG_GLOBAL_CONFIG)
    private dialogGlobalConfig: IDialogGlobalConfig,
    private matDialog: MatDialog
  ) {}

  //Open a generic dialog made of the component sent by the caller.
  //returns MatDialogRef<T> that gives the invoker the way to subscribe to the close event
  //of the popup.
  openDialog<T, D, R>(
    dialogComponent: ComponentType<T> | TemplateRef<T>,
    dialogConfig: IDialogConfig<D>
  ): MatDialogRef<T, R> {
    !dialogConfig.allowMultiple && this.closeDialog();

    return this.matDialog.open<T, D, R>(dialogComponent, dialogConfig);
  }

  /**
   * Close a generic dialog made of the component sent by the caller
   * of the popup.
   */
  closeDialog() {
    this.matDialog.closeAll();
  }
}
