import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ScanService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ScanGuard implements CanActivate {
  constructor(private scanService: ScanService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const docs = await this.scanService.getDocs();

    return !!docs;
  }
}
