import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, switchMap, take, throwError } from 'rxjs';
import { CustomerAuthenticationService } from '../../features/customer-authentication';

/**
  @description
  an HTTP interceptor which add an opportunity token to request headers,
  if the token exists on store.
  The token header will contain the opportunity token bearer + opportunity token.
**/
@Injectable({
  providedIn: 'root'
})
export class OpportunityTokenInterceptor implements HttpInterceptor {
  private opportunityTokenBearer = 'Calsale ';

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const opportunityToken$ = inject(CustomerAuthenticationService).otpToken;
    return opportunityToken$.pipe(
      take(1),
      catchError((err: unknown) => {
        return throwError(() => err);
      }),
      switchMap((opportunityToken: string) => {
        if (!opportunityToken) return next.handle(request);

        const requestClone = request.clone({
          headers: request.headers.append('opportunityauthorization', this.opportunityTokenBearer + opportunityToken)
        });

        return next.handle(requestClone);
      })
    );
  }
}
