import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthLogoutService } from '../services/auth-logout-service/auth-logout.service';
import { TokenService } from '../services/token-service/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenPersistencyGuard implements CanActivate {
  constructor(private tokenService: TokenService, private authLogoutService: AuthLogoutService) {}

  canActivate(): Observable<boolean> {
    return this.tokenService.orderToken$.pipe(
      take(1),
      map((token) => {
        // read token from local storage, check if our saved token.
        if (!token) {
          // means that there is no token saved on any storage.
          // if toke not exist, we will do logout
          this.authLogoutService.logout();
          return false;
        }
        return true;
      })
    );
  }
}
