import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './loader.interceptor';
import { OpportunityTokenInterceptor } from './opportunity-token.interceptor';
import { OrderTokenInterceptor } from './order-token.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: OpportunityTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: OrderTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
];
