/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BaseError } from '../models/error';
import { ErrorConfigService } from './error-config.service';
import { resolveErrorType } from '../functions/error.functions';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  /**
   ** @description decides what kind (type) of error provided via error parameter.
   * and insatiate a new relevant error class (apiError,network,client).
   * @param error
   */
  resolveError(error: Error): BaseError {
    //determine error type by its properties and creates an instance of it
    const resolvedErrorType = resolveErrorType(error);

    return resolvedErrorType;
  }
}
