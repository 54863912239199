import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ctdFeatureConfig]',
  standalone: true
})
export class FeatureConfigDirective {
  @HostBinding('class')
  get hostClass() {
    return `feature`;
  }
}
