import { Injectable } from '@angular/core';
import { httpResponseResult, HttpService } from '@cal2Deliver/core';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IdentificationStore } from '../../identification/services';
import { ScanStoreService } from '../../scan/services';
import { ICustomerOtpCheckModel, ICustomerOtpCheckData, ICustomerOtpModel, ICustomerOtpData } from '../model';
import { CustomerAuthenticationStore } from './customer-authentication-store';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthenticationService {
  constructor(
    private httpService: HttpService,
    private authStore: CustomerAuthenticationStore,
    private identificationStore: IdentificationStore,
    private scanStoreService: ScanStoreService
  ) {}

  get otpToken(): Observable<string> {
    return this.authStore.otpToken;
  }
  /**
   * @description invoke customer otp's http call.
   * @param model
   * @returns Promise<ICustomerAuthenticationResponse>
   */
  async sendCustomerOtpCheck(model: ICustomerOtpCheckModel): Promise<ICustomerOtpCheckData> {
    //save the request model in customer authentication store.
    this.authStore.dispatchSubmitOtpCheck(model);

    //create an observable made of our http request, basic error handling and extraction of the result from the full http response.
    const customerOtpCheck$ = this.httpService
      .post<ICustomerOtpCheckModel, ICustomerOtpCheckData>('customerOtpCheck', model)
      .pipe(
        catchError((error) =>
          throwError(() => {
            return error;
          })
        ),
        //extract result property from the full cal to deliver http response.
        httpResponseResult<ICustomerOtpCheckData>(),
        tap((result) => {
          //save the response model in authentication store (by updating the state).
          this.authStore.dispatchSubmitOtpCheckSuccess(result);
        })
      );
    //use lastValueFrom in order to transform our http call response into promise,
    //wait for it and return it.
    return await lastValueFrom(customerOtpCheck$);
  }
  async sendCustomerOtp(model: ICustomerOtpModel): Promise<ICustomerOtpData> {
    //save the request model in customer authentication store.
    this.authStore.dispatchSubmitOtp(model);

    const customerOtp$ = this.authStore.otpCheckData$.pipe(
      /**
       * get the current model token from store to complete otp
       */
      map((otpData: ICustomerOtpCheckData) => ({ ...model, otpToken: otpData?.token })),
      take(1),
      /**
       * call customer otp, wait for response
       */
      switchMap((otpModel: ICustomerOtpModel) =>
        this.httpService.post<ICustomerOtpModel, ICustomerOtpData>('customerOtp', otpModel)
      ),
      /**
       * extract result property from the http response
       */
      httpResponseResult<ICustomerOtpData>(),
      tap((result) => {
        //save the response model in authentication store (by updating the state).
        this.authStore.dispatchSubmitOtpSuccess(result);
      }),
      catchError((error) => throwError(() => error))
    );

    // transform the http response into promise, wait for it and return its response to consumer.
    return await lastValueFrom(customerOtp$);
  }
  resetCustomerAuthState() {
    this.authStore.dispatchResetState();
    this.identificationStore.dispatchResetState();
    this.scanStoreService.dispatchResetState();
  }
}
