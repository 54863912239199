import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { FlatButtonComponent } from '@cal2Deliver/ui';

@Component({
  selector: 'ctd-image-viewer',
  standalone: true,
  imports: [CommonModule, FlatButtonComponent],
  template: `
    <div mat-dialog-content>
      <img [src]="base64Image$ | async" style="max-height: 100%; width: 100%" />
    </div>
    <div mat-dialog-actions>
      <ctd-flat-button class="btn btn--sm" type="button" mat-dialog-close (click)="close()">
        <span>סגור</span>
      </ctd-flat-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ImageViewerDialogComponent {
  base64Image$: Observable<string>;

  constructor(@Inject(MAT_DIALOG_DATA) public base64Image: string, private dialogRef: MatDialogRef<unknown>) {
    this.base64Image$ = of(base64Image);
  }

  close() {
    this.dialogRef.close();
  }
}
