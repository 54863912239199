import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { delay, filter, map, Observable, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Features, IFeatureConfig, TFeatureName } from '../../../config';

@Component({
  selector: 'ctd-app-progress',
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-container *ngIf="currentRouteIndex$ | async as currentRouteIndex">
      <ng-container *ngFor="let feature of features">
        <span *ngIf="currentRouteIndex > feature.order" class="done"></span>
        <span *ngIf="currentRouteIndex === feature.order" class="active"></span>
        <span *ngIf="currentRouteIndex < feature.order" class="pristine"></span>
      </ng-container>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppProgressComponent {
  currentRouteIndex$: Observable<number>;
  features: IFeatureConfig[];

  @HostBinding('class')
  get hostClass() {
    return 'app-progress';
  }

  constructor(private router: Router) {
    this.features = Features.getAll();

    /**
     * @description A pipe extracts the url from the router,
     * gets the feature name out of the url, and return the feature order in the process.
     */
    this.currentRouteIndex$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url.replace('/', '')),
      map((currentRoute) => {
        try {
          return Features.get(currentRoute as TFeatureName).order;
        } catch (e) {
          /**
           * @description Used in case the url suffix is not valid, and the app is redirected to authentication feature.
           */
          return 1;
        }
      })
    );
  }
}
