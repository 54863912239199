import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { IPdfFormModel, TPdfViewer } from '../../../features/documents/model';
import { DocumentGalleryComponent } from '../../../features/documents';

@Component({
  selector: 'ctd-document-viewer-dialog',
  standalone: true,
  imports: [CommonModule, DocumentGalleryComponent],
  template: `
    <div mat-dialog-content>
      <ctd-document-gallery [forms]="forms" [pdfFiles]="pdfFiles" (documentsApproved)="close()"></ctd-document-gallery>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentGalleryDialogComponent implements OnInit, OnDestroy {
  forms: IPdfFormModel[];
  pdfFiles: TPdfViewer[];

  @HostBinding('class')
  get hostClass() {
    return `dialog--content-actions`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { forms: IPdfFormModel[]; pdfFiles: TPdfViewer[] },
    private dialogRef: MatDialogRef<unknown>,
    private meta: Meta
  ) {
    this.updateViewport('10.0');
  }

  ngOnInit(): void {
    const { forms, pdfFiles } = this.data;

    this.forms = forms;
    this.pdfFiles = pdfFiles;
  }

  ngOnDestroy(): void {
    this.updateViewport('1.0');
  }

  updateViewport(zoom: string) {
    this.meta.updateTag(
      {
        name: 'viewport',
        content: this.getViewportParams(zoom)
      },
      'name=viewport'
    );
  }

  getViewportParams(zoom: string) {
    return `width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=${zoom}`;
  }

  close() {
    this.dialogRef.close(true);
  }
}
