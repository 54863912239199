import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { DialogModule } from '@cal2Deliver/ui';
import { HttpModule } from '@cal2Deliver/core';
import { StoreModule } from '@ngrx/store';
import { featureReducers, RootEffects, rootMetaReducers } from './store';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { HttpInterceptorProviders } from './core';
import { httpConfig, ScanovateConfig } from './config';
import { CtdAppBodyComponent, CtdAppHeaderComponent } from './core/components';
import { CommonModule } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EncryptionModule } from '@cal2Deliver/utils';
import { encryptionConfig } from './config/encryption.config';
import { CtdErrorModule } from './core/error';
import { dialogGlobalConfig } from './config/dialog.config';
import { LoaderComponent } from './core/loader/components/loader.component';
import { provideNgxMask } from 'ngx-mask';
import { CalScanModule } from '@calsale/scanovate';
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CtdErrorModule,

    CalScanModule.forRoot(ScanovateConfig),
    EffectsModule.forRoot([RootEffects]),
    StoreModule.forRoot(featureReducers, {
      metaReducers: rootMetaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true // Pauses recording actions and state changes when the extension window is not open
    }),
    CtdAppHeaderComponent,
    CtdAppBodyComponent,
    LoaderComponent,
    HttpModule.forRoot(httpConfig),
    EncryptionModule.forRoot(encryptionConfig),
    DialogModule.forRoot(dialogGlobalConfig)
  ],
  providers: [
    HttpInterceptorProviders,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
