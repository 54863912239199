import { createAction, props } from '@ngrx/store';
import { ICustomerOtpCheckModel, ICustomerOtpCheckData, ICustomerOtpModel, ICustomerOtpData } from '../model';

export class CustomerAuthenticationActions {
  static reset = createAction('[Customer Authentication] reset');
  static otpCheckSubmit = createAction(
    '[Customer Authentication] Submit Customer Otp Check',
    props<{ otpCheckModel: ICustomerOtpCheckModel }>()
  );
  static otpCheckSubmitSuccess = createAction(
    '[Customer Authentication] Submit Customer Otp Check Success',
    props<{ otpCheckData: ICustomerOtpCheckData }>()
  );
  static otpCheckSubmitFailure = createAction(
    '[Customer Authentication] Submit Customer Otp Check Failure',
    props<{ error: any }>()
  );
  static otpSubmit = createAction(
    '[Customer Authentication] Submit Customer Otp Authentication',
    props<{ otpModel: ICustomerOtpModel }>()
  );
  static otpSubmitSuccess = createAction(
    '[Customer Authentication] Submit Customer Otp Authentication Success',
    props<{ otpData: ICustomerOtpData }>()
  );
  static otpSubmitFailure = createAction(
    '[Customer Authentication] Submit Customer Otp Authentication Failure',
    props<{ error: any }>()
  );
}
