import { map, Observable } from 'rxjs';
import { IHttpResponse } from '../models';

/**
 * @description get full typical calsale http response and extract the 'result property from it'
 * @returns R (result property from full http response)
 */
export function httpResponseResult<R>() {
  return (response: Observable<IHttpResponse<R>>): Observable<R> => {
    return response.pipe(map((data: IHttpResponse<R>) => data.result as R));
  };
}
