import { IHttpConfig } from '../models';
import { Inject, Injectable } from '@angular/core';

import { HTTP_CONFIG } from '../tokens/http.tokens';
import { THttpActionNames } from '../types';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigService {
  get httpConfig(): IHttpConfig {
    return this._httpConfig;
  }

  constructor(@Inject(HTTP_CONFIG) private _httpConfig: IHttpConfig) {}

  createActionUrl(key: THttpActionNames): string {
    const actionPath = this.getRouteByKey(key);
    if (!actionPath) {
      return '';
    }
    const { apiUrl, useProxy } = this._httpConfig;
    const fullActionRoute = useProxy ? actionPath : apiUrl + actionPath;
    return fullActionRoute;
  }

  private getRouteByKey(key: THttpActionNames): string | undefined {
    const { requestsMap } = this._httpConfig;
    return requestsMap?.get(key);
  }
}
