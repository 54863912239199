/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'apps/deliver/src/environments/environment';
import { ErrorAdapter } from '../services/error-adapter.service';
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private errorAdapter: ErrorAdapter) {}
  handleError(error: Error) {
    if (!environment.production) {
      //this method placed here because otherwise it will display only a partial typed error
      //and not a full type script error.
      this.showDebugError(error);
    }
    ///handle app errors from here using the relevant error class instance.
    this.errorAdapter.handleError(error);
    //
  }
  /**
   * write to console only in dev modes
   * (production is false)
   * */
  private showDebugError(error: Error) {
    console.log(`%c📕: I'm an error, do not ignore me..`, 'color: red');
    console.error(error);
    console.log(`%c📕: -------`, 'color: red');
  }
}
