/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { RoutingService } from '@cal2Deliver/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Features } from '../../../config/features.config';
import { AuthenticationActions } from './authentication-actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationEffects {
  submitLoginEffect$: Observable<Action> = createEffect(
    (): any =>
      this.actions$.pipe(
        ofType(AuthenticationActions.loginSubmitSuccess),
        tap(() => {
          const route = Features.get('customer-authentication')?.route;
          this.routerService.moveTo(route);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private routerService: RoutingService) {}
}
