import { Injectable } from '@angular/core';
import { HttpService, httpResponseResult } from '@cal2Deliver/core';
import { EncryptionService } from '@cal2Deliver/utils';
import { catchError, lastValueFrom, map, Observable, take, tap, throwError } from 'rxjs';
import { FileService, ISaveDocFileModel } from '../../../core';
import { ICourierDetails, IFormsData } from '../../identification/model/identification.interface';
import { IdentificationStore } from '../../identification/services/identification-store.service';
import { IPdfFormModel } from '../model';
import { DocumentsStore } from './documents-store.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  get peekForms$(): Observable<IFormsData> {
    return this.identificationStore.peekForms$;
  }

  constructor(
    private identificationStore: IdentificationStore,
    private documentsStore: DocumentsStore,
    private httpService: HttpService,
    private fileService: FileService,
    private encryptionService: EncryptionService
  ) {}

  /**
   * @description Creates a save file model in a valid format for the server.
   * @param base64Signature Signature base64 data.
   * @returns Save file model, with relevant properties.
   */
  createSaveFileModel(pdfForm: IPdfFormModel, base64Signature?: string): Partial<ISaveDocFileModel> {
    const {
      contentType,
      dependencyPdf,
      docCode,
      fileType,
      formType,
      isUseExtrenalServices,
      lastPage,
      signed,
      fileID,
      formCode,
      telecalDocCode,
      hash,
      pageHeight,
      pageWidth,
      signature
    } = pdfForm;

    //base64Signature should have value only for a form that requires signature
    let signatureData = '';
    let data = '';
    if (base64Signature) {
      //if hash has value, it means that the signature sign on a form belongs to loan or above 50K process and must be encrypted.
      //and sent via signData property.
      if (hash) {
        signatureData = this.encryptionService.encrypt(base64Signature, hash);
      } else {
        //means that the signature should not be encrypted and sent via data property.
        data = base64Signature;
      }
    }
    return {
      data,
      fileID,
      formCode,
      telecalDocCode,
      contentType,
      hash,
      isForm: true,
      pageHeight,
      pageWidth,
      signature,
      dependencyPdf,
      docCode,
      fileType,
      formType,
      isUseExtrenalServices,
      lastPage,
      signData: signatureData,
      signed
    };
  }
  saveFiles$(saveFileModel: Partial<ISaveDocFileModel>[]) {
    this.documentsStore.dispatchSaveFile(saveFileModel);

    return this.fileService.saveFiles(saveFileModel).pipe(
      take(1),
      httpResponseResult<boolean>(),
      catchError((error) => {
        this.documentsStore.dispatchSaveFileFailure(error);
        return throwError(() => error);
      })
    );
  }

  async submitDelivery(model: ICourierDetails) {
    this.documentsStore.dispatchSubmitFile(model);

    const delivery$ = this.httpService.post<ICourierDetails, boolean>('submitDelivery', model).pipe(
      catchError((error) => {
        this.documentsStore.dispatchSubmitFileFailure(error);
        return throwError(() => error);
      }),

      httpResponseResult<boolean>(),
      tap((isSubmitFileDataSuccess) => {
        this.documentsStore.dispatchSubmitFileSuccess(isSubmitFileDataSuccess);
      })
    );

    return await lastValueFrom(delivery$);
  }
}
