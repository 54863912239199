import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticationActions } from './authentication-actions';
import { IAuthenticationState } from './authentication-state';

export const initialAuthenticationState: IAuthenticationState = {
  loginModel: undefined,
  otpModel: undefined,
  loginData: undefined,
  otpData: undefined
};

export const authenticationReducerFn = createReducer(
  initialAuthenticationState,
  on(AuthenticationActions.reset, () => {
    return {
      ...initialAuthenticationState
    };
  }),
  on(AuthenticationActions.otpSubmit, (state: IAuthenticationState, action) => {
    const { otpModel } = action;
    return {
      ...state,
      ...{ otpModel }
    };
  }),
  on(AuthenticationActions.otpSubmitSuccess, (state: IAuthenticationState, action) => {
    const { otpData } = action;
    return {
      ...state,
      ...{ otpData }
    };
  }),
  on(AuthenticationActions.otpSubmitFailure, (state: IAuthenticationState, action) => {
    const { error } = action;
    return {
      ...state,
      ...{
        ...{ error: error }
      }
    };
  }),
  on(AuthenticationActions.loginSubmit, (state: IAuthenticationState, action) => {
    const { loginModel } = action;
    return {
      ...state,
      ...{ loginModel }
    };
  }),
  on(AuthenticationActions.loginSubmitSuccess, (state: IAuthenticationState, action) => {
    const { loginData } = action;
    return {
      ...state,
      ...{ loginData }
    };
  }),
  on(AuthenticationActions.loginSubmitFailure, (state: IAuthenticationState, action) => {
    const { error } = action;
    return {
      ...state,
      ...{
        ...{ error: error }
      }
    };
  })
);

export function authenticationReducer(state: IAuthenticationState | undefined, action: Action) {
  return authenticationReducerFn(state, action);
}
