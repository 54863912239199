import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ctd-app-body',
  imports: [CommonModule],
  template: `<ng-content></ng-content>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtdAppBodyComponent {
  @HostBinding('class')
  get hostClass() {
    return 'app-body';
  }
}
