import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { toggleFadeAnimation } from '../../animations';

@Component({
  standalone: true,
  selector: 'ctd-loader',
  imports: [CommonModule, MatProgressSpinnerModule],
  template: `<ng-container
    ><mat-spinner class="loader-spinner" diameter="70" strokeWidth="2"></mat-spinner
  ></ng-container>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleFadeAnimation]
})
export class LoaderComponent {
  @HostBinding('class')
  get hostClass() {
    return `app-loader`;
  }
}
