/* eslint-disable @typescript-eslint/member-ordering */
import { createSelector } from '@ngrx/store';
import { ICustomerOtpCheckData, ICustomerOtpCheckModel, ICustomerOtpData, ICustomerOtpModel } from '../model';
import { ICustomerAuthenticationState } from './customer-authentication-state';

export class CustomerAuthenticationSelectors {
  static customerAuthState = (state): ICustomerAuthenticationState => {
    return state.customerAuthentication;
  };

  static otpCheckModel = createSelector(
    CustomerAuthenticationSelectors.customerAuthState,
    (customerAuthState: ICustomerAuthenticationState): ICustomerOtpCheckModel => {
      return customerAuthState.otpCheckModel;
    }
  );

  static otpCheckData = createSelector(
    CustomerAuthenticationSelectors.customerAuthState,
    (customerAuthState: ICustomerAuthenticationState): ICustomerOtpCheckData => {
      return customerAuthState.otpCheckData;
    }
  );

  static otpModel = createSelector(
    CustomerAuthenticationSelectors.customerAuthState,
    (customerAuthState: ICustomerAuthenticationState): ICustomerOtpModel => {
      return customerAuthState.otpModel;
    }
  );

  static otpData = createSelector(
    CustomerAuthenticationSelectors.customerAuthState,
    (customerAuthState: ICustomerAuthenticationState): ICustomerOtpData => {
      return customerAuthState.otpData;
    }
  );
}
