/* eslint-disable @typescript-eslint/no-empty-interface */

import { TError } from './error.type';

export interface IBaseError extends Partial<Error> {
  title?: string;
  description?: string;
  errorType: TError;
}

export interface IApiError extends IBaseError {
  statusCode: number;
  messageCode?: string;
}
export interface IClientError extends IBaseError {}
export interface INetworkError extends IBaseError {
  statusCode: number;
}
