import { createAction, props } from '@ngrx/store';
import { ISaveDocFileModel } from '../../../core';
import { ICourierDetails } from '../../identification/model';

export class DocumentsActions {
  static saveFile = createAction('[Documents] Save File', props<{ saveFileModel: Partial<ISaveDocFileModel>[] }>());
  static saveFileSuccess = createAction('[Documents] Save File Success', props<{ isFileDataSentSuccess: boolean }>());
  static saveFileFailure = createAction('[Documents] Save File Failure', props<{ error: any }>());

  static submitFile = createAction('[Documents] Submit File', props<{ submitFileModel: ICourierDetails }>());
  static submitFileSuccess = createAction(
    '[Documents] Submit File Success',
    props<{ isSubmitFileDataSuccess: boolean }>()
  );
  static submitFileFailure = createAction('[Documents] Submit File Failure', props<{ error: any }>());
}
