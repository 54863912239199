import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPdfFormModel, TPdfViewer } from '../../model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlatButtonComponent, IPdfViewerDataSource, PdfViewerComponent, StrokedButtonComponent } from '@cal2Deliver/ui';
import { BehaviorSubject, filter, finalize, map, Observable, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { IFormSignature } from '../../../identification/model';
import { FileService } from '../../../../core';
import { DialogAdapter } from '../../../../shared';
import { DocumentsService } from '../../services';

@Component({
  selector: 'ctd-document-item',
  standalone: true,
  imports: [CommonModule, PdfViewerComponent, FlatButtonComponent, StrokedButtonComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DocumentItemComponent)
    }
  ],
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentItemComponent implements ControlValueAccessor, OnInit {
  @Input()
  file: TPdfViewer;
  @Input()
  pdfForm: IPdfFormModel;

  @Output()
  documentApproved: EventEmitter<void> = new EventEmitter();

  base64Signature$: Observable<string | null>;
  file$: Observable<IPdfViewerDataSource>;

  private base64SignatureEmitter = new BehaviorSubject<string | null>(null);
  private onChange: (isSignatureSubmitted: boolean) => void;

  constructor(
    private fileService: FileService,
    private dialogAdapter: DialogAdapter,
    private changeDetector: ChangeDetectorRef,
    private documentService: DocumentsService
  ) {
    this.base64Signature$ = this.base64SignatureEmitter.asObservable();
  }

  ngOnInit(): void {
    /**
     * @description Add signature to pdf viewer config, when signature exists
     */
    this.file$ = this.base64Signature$.pipe(
      withLatestFrom(of(this.file)),
      map(
        ([signature, file]) => ({ ...file, base64Signature: signature ? signature : undefined } as IPdfViewerDataSource)
      )
    );
  }

  writeValue(value: boolean | null): void {}

  registerOnChange(valueChangeCallbackFunc: (isSubmitSucceeded: boolean) => void): void {
    this.onChange = valueChangeCallbackFunc;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(onTouchedCallbackFunc: () => void): void {}

  /**
   * @description Resolves a document's required action: either "approval" or "signature", if a document doesnt require a signature, it automatically requires an approval
   * @param file The pdf file metadata
   * @returns Object which indicates what requirements the pdf file has
   */
  getDocRequirements$(file: IPdfViewerDataSource): Observable<{ isSignatureRequired }> {
    const isSignatureRequired = file.pages.some((page) => {
      const hasSignatureData = page.signatures.some((signature) => {
        return Boolean(signature as IFormSignature);
      });

      return hasSignatureData;
    });

    return of({ isSignatureRequired });
  }

  approveDocument(file: IPdfViewerDataSource) {
    this.getDocRequirements$(file)
      .pipe(
        take(1),
        withLatestFrom(this.base64Signature$),
        filter(
          ([docRequirements, base64Signature]) =>
            !docRequirements?.isSignatureRequired || !!(docRequirements?.isSignatureRequired && base64Signature)
        ),
        switchMap(([_, base64Signature]) => {
          const saveFileModel = [this.documentService.createSaveFileModel(this.pdfForm, base64Signature)];
          return this.fileService.saveFiles(saveFileModel);
        }),
        tap(() => {
          this.onChange(true);
          this.documentApproved.emit();
        })
      )
      .subscribe();
  }

  /**
   * @description Opens the signature pad dialog, and on close of the dialog in success,
   * sends the signature to the server.
   * After receiving a success response from the server,
   * emits to the form that the signatures process in current control ended with success.
   * @param file
   */
  showSignaturePad(file: TPdfViewer) {
    const dialogRef = this.dialogAdapter.showSignaturePad({ data: file });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((data) => !!data),
        tap(({ base64Signature }) => {
          this.base64SignatureEmitter.next(base64Signature);
        }),
        finalize(() => this.changeDetector.detectChanges())
      )
      .subscribe();
  }

  isSigned$() {
    return this.base64Signature$.pipe(map((base64Signature) => !!base64Signature));
  }
}
