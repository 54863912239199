import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISaveScanFileModel } from '../../../core/services';
import { IDocsData, IDocumentData, IScanovateErrorCount } from '../model';
import { IScanState, ScanActions } from '../store';
import { ScanSelectors } from '../store/scan-selectors';

@Injectable({
  providedIn: 'root'
})
export class ScanStoreService {
  readonly documents$: Observable<IDocumentData[]> = this.store.select(ScanSelectors.documents);
  readonly scanovateErrorsCounter$: Observable<IScanovateErrorCount> = this.store.select(
    ScanSelectors.scanovateErrorsCounter
  );

  constructor(private store: Store<IScanState>) {}

  dispatchResetState() {
    this.store.dispatch(ScanActions.reset());
  }

  dispatchDocsData() {
    this.store.dispatch(ScanActions.loadDocuments());
  }

  dispatchDocsDataSuccess(docsData: IDocsData) {
    this.store.dispatch(ScanActions.loadDocumentsSuccess({ docsData: docsData }));
  }

  dispatchDocsDataFailure(err: any) {
    this.store.dispatch(ScanActions.loadDocumentsFailure({ err }));
  }

  dispatchSaveFilesModel(saveFilesModel: Partial<ISaveScanFileModel>[]) {
    this.store.dispatch(ScanActions.saveFiles({ saveFilesModel }));
  }

  dispatchSaveFilesSuccess(response: boolean) {
    this.store.dispatch(ScanActions.saveFilesSuccess({ saveFilesResponse: response }));
  }

  dispatchSaveFilesFailure(err: any) {
    this.store.dispatch(ScanActions.saveFilesFailure({ err }));
  }

  dispatchScanovateFailure(docCode: number) {
    this.store.dispatch(ScanActions.scanovateFailure({ docCode }));
  }
}
