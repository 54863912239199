import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpContextService } from '@cal2Deliver/core';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from '../loader';
import { IAppHttpContext } from '../model/locals.interface';

/**
  @description
  An HTTP interceptor which will toggle loader on/off state
**/
@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // const loaderContext: IHttpContext = request.context.get(HTTP_CONTEXT);

    const loaderContext = HttpContextService.getContext<IAppHttpContext>(request);

    if (!loaderContext?.omitLoader) {
      this.loaderService.turnOn();
    }
    return next.handle(request).pipe(
      finalize(() => {
        if (!loaderContext?.omitLoader) {
          this.loaderService.turnOff();
        }
      })
    );
  }
}
