import { NgModule } from '@angular/core';
import { AssetContainerComponent } from './components';

const UiComponentsRegisty = [AssetContainerComponent];
@NgModule({
  imports: [UiComponentsRegisty],
  exports: [UiComponentsRegisty],
  declarations: []
})
export class CtdUiModule {}
