import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { IFormsData } from '../model';
import { IdentificationActions, IIdentificationState } from '../store';
import { IdentificationSelectors } from '../store/identification-selectors';

@Injectable({
  providedIn: 'root'
})
export class IdentificationStore {
  readonly forms$: Observable<IFormsData> = this.store.select(IdentificationSelectors.formsData);

  constructor(private store: Store<IIdentificationState>) {}

  dispatchResetState() {
    this.store.dispatch(IdentificationActions.reset());
  }

  dispatchLoadFormsSuccess(formsData: IFormsData) {
    this.store.dispatch(IdentificationActions.loadSuccess({ formsData }));
  }
  dispatchLoadFormsFailure(error: unknown) {
    this.store.dispatch(IdentificationActions.loadFailure({ error }));
  }
  get peekForms$(): Observable<IFormsData> {
    return this.forms$.pipe(take(1));
  }
}
