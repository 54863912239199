import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { IDialogGlobalConfig } from './models/dialog.interface';
import { CAL_DIALOG_GLOBAL_CONFIG } from './token/dialog.token';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatBottomSheetModule]
})
export class DialogModule {
  static forRoot(dialogConfig: IDialogGlobalConfig) {
    return {
      ngModule: DialogModule,
      providers: [
        {
          provide: CAL_DIALOG_GLOBAL_CONFIG,
          useValue: dialogConfig
        }
      ]
    };
  }
}
