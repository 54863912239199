/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { IPdfPage, IPdfSignatureCoordinates, IPdfViewerDataSource } from '../models';
import { BehaviorSubject, fromEvent, map, Observable, Subscription, tap, throttleTime, withLatestFrom } from 'rxjs';

@Component({
  selector: 'pdf-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class')
  get hostClass() {
    return 'pdf-viewer';
  }

  @Input()
  pdfViewerConfig: Observable<IPdfViewerDataSource>;

  @Output()
  pendingSigning: EventEmitter<void> = new EventEmitter();

  /**
   * @description The data that is coming from the pdfViewerConfig input is an absolute raw data (documents and signatures sized, pdf image format, etc.) that comes from the server.
   * For presentation of the data, we need to adjust (normalize) it fit different screen sizes.
   */
  config$: Observable<IPdfViewerDataSource>;

  private subscriptions = new Subscription();
  /**
   * @description See comment above config$ variable.
   */
  private config = new BehaviorSubject<IPdfViewerDataSource>(null);

  @ViewChild('pdfViewerContainer')
  pdfViewerContainer: ElementRef<HTMLDivElement>;

  constructor(@Inject(DOCUMENT) public document: Document, private changeDetector: ChangeDetectorRef) {
    this.config$ = this.config.asObservable();
  }

  ngAfterViewInit(): void {
    this.createDataSourceChangeHandler();
    this.createOnResizeHandler();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description Emits to parent component, that the signature placeholder was clicked.
   */
  onSigningRequest() {
    this.pendingSigning.emit();
  }

  /**
   * @description Creates a pipe that listens to pdfViewerConfig input, normalizes it's data, and emits that to config$ through the config behavior subject.
   * We need to track changes on the pdfViewerConfig input data, each change will trigger another pdf normalization call.
   */
  private createDataSourceChangeHandler() {
    const configManipulationSubscription = this.pdfViewerConfig
      .pipe(
        map((pdfViewerConfig) => this.normalizeDataSource(pdfViewerConfig)),
        tap((pdfViewerConfig) => {
          this.config.next(pdfViewerConfig);
          this.changeDetector.detectChanges();
        })
      )
      .subscribe();

    this.subscriptions.add(configManipulationSubscription);
  }
  /**
   * @description Creates a pipe that listens to window size chnages, normalizes pdfViewerConfig input data, and emits that to config$ through the config behavior subject.
   * We need to track window resize event in order to redraw (or reposition) the signature pad on the changing coordinates, each change will trigger another pdf normalization call.
   */
  private createOnResizeHandler() {
    const resizeSubscription = fromEvent(this.document.defaultView as Window, 'resize')
      .pipe(
        throttleTime(50, null, { leading: true, trailing: true }),
        withLatestFrom(this.pdfViewerConfig),
        map(([_, pdfViewerConfig]) => this.normalizeDataSource(pdfViewerConfig)),
        tap((pdfViewerConfig) => {
          this.config.next(pdfViewerConfig);
          this.changeDetector.detectChanges();
        })
      )
      .subscribe();

    this.subscriptions.add(resizeSubscription);
  }

  /**
   * @description Calculates the pdfViewerConfig input normalized data, based on the pdf factors and screen size.
   * @param pdfViewerData The pdf viewer data.
   * @returns Normalized pdf viewer data.
   */
  private normalizeDataSource(pdfViewerData: IPdfViewerDataSource): IPdfViewerDataSource {
    return {
      base64Signature: pdfViewerData ? this.dataUrlToBase64(pdfViewerData.base64Signature) : null,
      pages: this.mapNormalizedPDFPages(pdfViewerData.pages)
    };
  }

  /**
   * @description Calculates the ratio between the page screen view to the pdf document
   * @param pageWidth The page component actual screen pixel size.
   * @returns The ratio between the page screen view to the pdf document.
   */
  private calcComponentSizeToDocumentRatio(pageWidth: number) {
    return pageWidth / this.pdfViewerContainer.nativeElement.scrollWidth;
  }

  /**
   * @description Calculates the size of the pdf document to fit screen size, based on the pdf factors and screen size.
   * @param pages The pdf pages data.
   * @returns Normalized pdf pages data.
   */
  private mapNormalizedPDFPages(pages: IPdfPage[]) {
    return pages.map((page: IPdfPage): IPdfPage => {
      const componentToDocumentRatio = this.calcComponentSizeToDocumentRatio(page.width);

      return {
        height: page.height / componentToDocumentRatio,
        width: page.width / componentToDocumentRatio,
        pageContentDataUrl: this.dataUrlToBase64(page.pageContentDataUrl),
        signatures: this.mapNormalizedPDFSignatures(page.signatures, componentToDocumentRatio)
      };
    });
  }

  /**
   * @description Calculates the position (coordinates) of the signature page insize the pdf document, based on the pdf factors and screen size.
   * @param signatures The pdf signatures data.
   * @returns Normalized pdf signatures data.
   */
  private mapNormalizedPDFSignatures(signatures: IPdfSignatureCoordinates[], componentToDocumentRatio: number) {
    return signatures.map(
      (signature: IPdfSignatureCoordinates): IPdfSignatureCoordinates => ({
        bottom: signature.bottom / componentToDocumentRatio,
        left: signature.left / componentToDocumentRatio,
        height: signature.height / componentToDocumentRatio,
        width: signature.width / componentToDocumentRatio
      })
    );
  }

  /**
   * @description Converts data url to base64 image.
   * Used to enable presentation of dataUrl on image element, since image element source should be base64 image.
   * @param dataUrl The data url.
   * @returns Base64 image.
   */
  private dataUrlToBase64(dataUrl: string): string {
    if (!dataUrl) {
      return null;
    }
    return 'data:image/jpg;base64,' + dataUrl;
  }
}
