import { Injectable } from '@angular/core';
import { StorageHandler } from '@cal2Deliver/utils';
import { RootStore } from '../../../store/services/root-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLogoutService {
  constructor(private rootState: RootStore) {}

  /**
   * @description reset local storage and state
   * reload app
   */
  logout() {
    //reset local storage
    StorageHandler.clearAll();
    this.rootState.dispatchResetState();
  }
}
