/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService, IConfirmDialog, IDialogConfig, IErrorDialog, ModalConfig } from '@cal2Deliver/core';
import { ISignaturePadDialog, IPdfViewerDialog, IPdfFormModel, TPdfViewer } from '../../../features/documents/model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DocumentGalleryDialogComponent } from '../document-gallery-dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { ImageViewerDialogComponent } from '../image-viewer-dialog';
import { NetworkErrorDialogComponent } from '../network-error-dialog/network-error-dialog.component';
import { SignaturePadDialogComponent } from '../signature-pad-dialog';

const defaultDocumentViewerOptions = {
  data: {
    pdfFiles: null,
    forms: null
  },
  panelClass: ['dialog', 'dialog--document-view']
};
const defaultImageViewerOptions = {
  data: null,
  panelClass: ['dialog', 'dialog--image-view']
};
const defaultErrorOptions: IDialogConfig<IErrorDialog> = {
  matchToMedia: true,
  panelClass: ['dialog', 'dialog--error']
};

const defaultConfirmOptions: IDialogConfig<IConfirmDialog> = {
  matchToMedia: true,
  panelClass: ['dialog', 'dialog--confirm']
};
const defaultSignaturePadOptions = {
  allowMultiple: true,
  panelClass: ['dialog', 'dialog--signature-pad']
};

@Injectable({
  providedIn: 'root'
})
export class DialogAdapter {
  constructor(private dialogService: DialogService) {}
  /**
   * @description
   * open generic option dialog
   *
   */
  show() {
    return this.openDialog(GenericDialogComponent);
  }

  showSignaturePad<T = ISignaturePadDialog, D = SignaturePadDialogComponent, R = any>(
    dialogConfig: ModalConfig = {}
  ): MatDialogRef<T, R> {
    const signatureConfig = this.autoCustomize(dialogConfig, defaultSignaturePadOptions);
    return this.openDialog<T, D, R>(SignaturePadDialogComponent as ComponentType<T>, signatureConfig);
  }

  showPdfViewer<T = IPdfViewerDialog, D = DocumentGalleryDialogComponent, R = any>(
    forms: IPdfFormModel[],
    pdfFiles: TPdfViewer[],
    options: ModalConfig = {}
  ): MatDialogRef<T, R> {
    defaultDocumentViewerOptions.data = { forms, pdfFiles };
    options = {
      ...defaultDocumentViewerOptions,
      ...options,
      ...{ minHeight: '100%', minWidth: '100%', height: '100%', width: '100%' }
    };
    return this.openDialog<T, D, R>(DocumentGalleryDialogComponent as ComponentType<T>, options);
  }

  /**
   * @description
   * open network error dialog
   *
   */
  showNetworkError<T = NetworkErrorDialogComponent, D = IErrorDialog, R = any>(
    dialogConfig?: IDialogConfig<IErrorDialog>
  ) {
    const errorConfig = this.autoCustomize<IErrorDialog>(dialogConfig, defaultErrorOptions);
    return this.openDialog<T, IErrorDialog, R>(NetworkErrorDialogComponent as ComponentType<T>, errorConfig);
  }
  /**
   * @description
   * open error option dialog
   *
   * @param dialogConfig dialog options
   */
  showError<T = ErrorDialogComponent, D = IErrorDialog, R = any>(
    dialogConfig?: IDialogConfig<IErrorDialog>
  ): MatDialogRef<T, R> {
    const errorConfig = this.autoCustomize<IErrorDialog>(dialogConfig, defaultErrorOptions);
    return this.openDialog<T, IErrorDialog, R>(ErrorDialogComponent as ComponentType<T>, errorConfig);
  }
  /**
   * @description
   * open confirm option dialog
   *
   * @param dialogConfig dialog options
   */
  showConfirm(dialogConfig: IDialogConfig) {
    const confirmConfig = this.autoCustomize(dialogConfig, defaultConfirmOptions);
    return this.openDialog(ConfirmDialogComponent, confirmConfig);
  }
  /**
   * @description
   * opens a customize dialog
   *
   * @param dialogContent dialog content - component or template ref
   * @param dialogConfig dialog options
   */
  private openDialog<T, D, R>(
    dialogContent: ComponentType<T> | TemplateRef<T>,
    dialogConfig?: IDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.dialogService.openDialog<T, D, R>(dialogContent, dialogConfig);
  }

  /**
   * @description
   * closes all types of dialog
   *
   */
  closeDialog() {
    return this.dialogService.closeDialog();
  }

  /**
   * @description
   * add some customization depending on which device/platform the app is running
   * example: customize dialog appearance as bottom sheet in case of small screen
   * @param dialogConfig
   */
  private autoCustomize<D>(dialogConfig: IDialogConfig<D>, defaultConfig: IDialogConfig<D>): IDialogConfig<D> {
    const customConfig = Object.assign(
      {},
      { ...defaultConfig },
      { ...dialogConfig },
      {
        width: '100%',
        position: { top: 'auto', bottom: 0, right: 0, left: 0 },
        panelClass: [
          ...(defaultConfig?.panelClass || []),
          ...(dialogConfig?.panelClass || []),
          'dialog--bottom-sheet',
          'dialog--animated',
          'dialog--slide-in-up'
        ]
      }
    );

    return customConfig;
  }

  /**
   * @description Opens the image viewer dialog.
   * @param dialogConfig Dialog options
   */
  showImageViewer(dialogConfig: IDialogConfig<string>) {
    const imageBiewerConfig = this.autoCustomize(dialogConfig, defaultImageViewerOptions);
    return this.openDialog(ImageViewerDialogComponent, imageBiewerConfig);
  }
}
