import { Observable, of } from 'rxjs';
import { TStorageKey } from './types';

/*A simple utils class providing an api for localStorage actions */
export class StorageHandler {
  /**
   * @description save item to localStorage with a given key and data.
   * @param key
   * @param obj
   */
  static saveItem<T>(key: TStorageKey, obj: T) {
    localStorage.setItem(key, JSON.stringify(obj));
  }
  /**
   * @description update current item to localStorage with a given key and data.
   * @param key
   * @param obj
   */
  static updateItem<T>(key: TStorageKey, obj: T) {
    const parsedItem = JSON.parse(fetchItem(key));
    const updateItem = Object.assign({}, { ...parsedItem, ...obj });
    localStorage.setItem(key, JSON.stringify(updateItem));
  }
  /**
   * remove item in localStorage by a given key.
   * @param key
   */
  static removeItem(key: TStorageKey) {
    localStorage.removeItem(key);
  }
  /**
   * @description get item from localStorage, parse it and return it in an observable.
   * @param key
   * @returns Observable<T>
   */
  static fetchItem$<T>(key: TStorageKey): Observable<T> {
    try {
      const unParsedItem = fetchItem(key);
      return of(JSON.parse(unParsedItem)) as Observable<T>;
    } catch (error) {
      return of(null);
    }
  }
  /*clear all items in localStorage */
  static clearAll() {
    localStorage.clear();
  }
}
function fetchItem(key: TStorageKey): string {
  return localStorage.getItem(key.toString());
}
