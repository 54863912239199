<ng-container *ngIf="getDocRequirements$(file) | async as docRequirements">
  <pdf-viewer [pdfViewerConfig]="file$" (pendingSigning)="showSignaturePad(file)"></pdf-viewer>



  <div class="submit-wrapper screen-margin">
    <ctd-flat-button class="btn btn--sm"
      [disabled]="docRequirements?.isSignatureRequired && (isSigned$() | async) === false" type="button"
      class="submit-button" (click)="approveDocument(file)">
      <span class="typo typo--sm typo--semibold">לאישור המסמך</span>
    </ctd-flat-button>
  </div>
</ng-container>