import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { DocGalleryDividerDirective } from './doc-divider-slot.directive';
import { DocGalleryItemDirective } from './doc-item-slot.directive';

@Component({
  selector: 'ctd-docs-gallery',
  standalone: true,
  imports: [CommonModule, MatRippleModule],
  template: `
    <ng-container *ngTemplateOutlet="divTemplate?.tpl"></ng-container>

    <div *ngFor="let doc of dataSource; let i = index; let isLast = last">
      <ng-container *ngTemplateOutlet="docTemplate.tpl; context: { $implicit: { doc, i } }"></ng-container>
      <ng-container *ngTemplateOutlet="divTemplate?.tpl"></ng-container>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocsGalleryComponent {
  @Input()
  dataSource: unknown[];

  @ContentChild(DocGalleryItemDirective, { static: false })
  docTemplate: DocGalleryItemDirective;

  @ContentChild(DocGalleryDividerDirective, { static: false })
  divTemplate: DocGalleryDividerDirective;

  @HostBinding('class')
  get hostCalss() {
    return 'docs-gallery';
  }
}
