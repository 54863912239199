import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHttpConfig } from './models/environment.interface';
import { HTTP_CONFIG } from './tokens/http.tokens';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],

  imports: [CommonModule, HttpClientModule]
})
export class HttpModule {
  static forRoot(httpConfig: IHttpConfig) {
    return {
      ngModule: HttpModule,
      providers: [
        {
          provide: HTTP_CONFIG,
          useValue: httpConfig
        }
      ]
    };
  }
}
