/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { ILoginModel, ILoginData, IOtpModel, IOtpData } from '../model';

export class AuthenticationActions {
  static reset = createAction('[Authentication] reset');

  static otpSubmit = createAction('[Authentication] Submit Otp', props<{ otpModel: IOtpModel }>());
  static otpSubmitSuccess = createAction('[Authentication] Submit Otp Success', props<{ otpData: IOtpData }>());
  static otpSubmitFailure = createAction('[Authentication] Submit Otp Failure', props<{ error: any }>());

  static loginSubmit = createAction('[Authentication] Submit Login', props<{ loginModel: ILoginModel }>());
  static loginSubmitSuccess = createAction('[Authentication] Submit Login Success', props<{ loginData: ILoginData }>());
  static loginSubmitFailure = createAction('[Authentication] Submit Login Failure', props<{ error: any }>());
}
