import { Injectable } from '@angular/core';
import { httpResponseResult, HttpService } from '@cal2Deliver/core';
import { StorageHandler } from '@cal2Deliver/utils';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { RootStore } from '../../../store/services/root-store.service';
import { ICourierDetails } from '../../identification/model';
import { ILoginData, ILoginModel, IOtpData, IOtpModel } from '../model';
import { AuthenticationStore } from './authentication-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  get otpData$(): Observable<IOtpData> {
    return this.authStore.otpData$;
  }

  constructor(private httpService: HttpService, private authStore: AuthenticationStore, private rootState: RootStore) {}

  /**
   * @description invoke otp's http call.
   * @param model
   * @returns Promise<IOtpData>
   */
  async sendOtp(model: IOtpModel): Promise<IOtpData> {
    //save the request model in authentication store.
    this.authStore.dispatchSubmitOtp(model);

    //create an observable made of our http request, basic error handling and extraction of the result from the full http response.
    const otp$ = this.httpService.post<IOtpModel, IOtpData>('externalUserOtp', model).pipe(
      catchError((error) => throwError(() => error)),
      take(1),
      //extract result property from the full calsale http response.
      httpResponseResult<IOtpData>(),
      tap((result) => {
        const { custIdNum } = model;
        StorageHandler.saveItem<ICourierDetails>('courier', {
          courierId: custIdNum
        } as Partial<ICourierDetails>);
        //save the response model in authentication store (by updating the state).
        this.authStore.dispatchSubmitOtpSuccess(result);
      })
    );
    //use lastValueFrom in order to transform our http call response into promise,
    //wait for it and return it.
    return await lastValueFrom(otp$);
  }

  async sendLogin(model: ILoginModel): Promise<ILoginData> {
    //save the request model in authentication store.
    this.authStore.dispatchLogin(model);
    const userLogin$ = this.authStore.otpData$.pipe(
      catchError((error) => throwError(() => error)),
      /**
       * get the current model token from store to complete otp
       */
      map((otpData: IOtpData) => ({ ...model, otpToken: otpData?.token })),
      take(1),
      /**
       * call customer otp, wait for response
       */
      switchMap((loginModel: ILoginModel) =>
        this.httpService.post<ILoginModel, ILoginData>('externalUserLogin', loginModel)
      ),
      /**
       * extract result property from the http response
       */
      httpResponseResult<ILoginData>(),
      tap((result) => {
        //save orderToken in localStorage
        const { digitalDeliveryToken, fisrtName: firstName, lastName } = result;

        StorageHandler.updateItem<ICourierDetails>('courier', {
          firstName,
          lastName
        } as Partial<ICourierDetails>);

        StorageHandler.saveItem('orderToken', digitalDeliveryToken);
        //save the response model in authentication store (by updating the state).
        this.authStore.dispatchLoginSuccess(result);
      })
    );

    // transform the http response into promise, wait for it and return its response to consumer.
    return await lastValueFrom(userLogin$);
  }
  resetAuthenticationState() {
    this.authStore.dispatchResetAuthenticationState();
  }
}
