/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { filter, Observable, take, tap } from 'rxjs';
import { CustomerAuthenticationService } from '../services/customer-authentication.service';
import { CustomerAuthenticationStore } from '../services/customer-authentication-store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatInputModule } from '@angular/material/input';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ICustomerOtpCheckModel, ICustomerOtpModel } from '../model';
import { CustomValidators, FeatureConfigDirective } from '../../../core';
import { Features } from '../../../config/features.config';
import { FlatButtonComponent } from '@cal2Deliver/ui';
import { toggleFadeAnimation } from '../../../core/animations';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    EffectsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    FlatButtonComponent
  ],
  templateUrl: './customer-authentication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [FeatureConfigDirective],
  animations: [toggleFadeAnimation]
})
export class CtdCustomerAuthenticationComponent implements OnInit {
  readonly feature = Features.get('customer-authentication');

  readonly customerOtpCheckModel$: Observable<ICustomerOtpCheckModel> = this.customerAuthStore.otpCheckModel$;
  readonly customerOtpModel$: Observable<ICustomerOtpModel> = this.customerAuthStore.otpModel$;
  readonly isOtpRequired$: Observable<boolean> = this.customerAuthStore.isOtpRequired$;
  readonly customerPhone$: Observable<string> = this.customerAuthStore.customerPhone$;

  authFormGroup: FormGroup;

  constructor(
    private customerAuthStore: CustomerAuthenticationStore,
    private customerAuthService: CustomerAuthenticationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.authFormGroup = this.fb.group({
      custIdNum: [
        { value: '', disabled: false },
        [Validators.required, Validators.minLength(5), Validators.maxLength(9)]
      ],
      deliveryBarcode: [
        { value: '', disabled: false },
        [Validators.required, Validators.minLength(5), Validators.maxLength(13)]
      ],
      otpPassword: [null, []]
    });
    this.customerAuthService.resetCustomerAuthState();
  }

  /**
   * @description
   *  check if customer otp was required by indicator from store.
     if indicator value is false, we will invoke send otp call.
     else, invoke otp api call.
   */
  submitForm() {
    this.isOtpRequired$
      .pipe(
        take(1),
        filter(() => this.authFormGroup.valid),
        tap(async (isOtpRequired: boolean) => {
          const { custIdNum, deliveryBarcode, otpPassword } = this.authFormGroup.value;
          if (!isOtpRequired) {
            await this.customerAuthService.sendCustomerOtpCheck({
              custIdNum,
              deliveryBarcode
            });
            this.authFormGroup.get('custIdNum').disable();
            this.authFormGroup.get('deliveryBarcode').disable();

            this.authFormGroup
              .get('otpPassword')
              .setValidators([Validators.required, CustomValidators.lengthValidator(6)]);
            this.authFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
          } else {
            await this.customerAuthService.sendCustomerOtp({
              otpPassword
            });
          }
        })
      )
      .subscribe();
  }
}
