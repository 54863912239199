/* eslint-disable @typescript-eslint/member-ordering */
import { createSelector } from '@ngrx/store';
import { IDocumentData } from '../model';
import { IScanState } from './scan-state';

export class ScanSelectors {
  private static scanState = (state): IScanState => {
    return state.scan;
  };

  static documents = createSelector(ScanSelectors.scanState, (scanState: IScanState): IDocumentData[] => {
    return scanState.documentsData.documents;
  });

  static scanovateErrorsCounter = createSelector(ScanSelectors.scanState, (scanState: IScanState) => {
    return scanState.scanovateErrorCounter;
  });
}
