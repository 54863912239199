import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootActions } from '../root.actions';
import { RootSelectors } from '../root.selectors';
import { IRootState } from '../root.state';

@Injectable({
  providedIn: 'root'
})
export class RootStore {
  readonly rootState$: Observable<IRootState>;

  constructor(private rootStore: Store<IRootState>) {
    this.rootState$ = this.rootStore.select(RootSelectors.rootState);
  }

  dispatchResetState() {
    this.rootStore.dispatch(RootActions.reset());
  }
}
