import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AssetContainerComponent } from '@cal2Deliver/ui';
@Component({
  standalone: true,
  selector: 'ctd-generic-dialog',
  imports: [CommonModule, AssetContainerComponent],
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialogComponent {
  constructor(private dialogRef: MatDialogRef<unknown>) {}
  close() {
    this.dialogRef.close();
  }
}
