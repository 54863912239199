import { Inject, Injectable } from '@angular/core';
import { IEncryptionConfig } from '../models';
import { ENCRYPTION_CONFIG } from '../tokens';
import JSEncrypt from 'jsencrypt';

@Injectable()
export class EncryptionService {
  constructor(@Inject(ENCRYPTION_CONFIG) private encryptionConfig: IEncryptionConfig) {}

  /**
   * @description Recieves a string and encrypts it by the key.
   * Only the first n (where n is a IEncryptionConfig encryptedSubstringLength field) letters of the value string will be encrypted.
   * The result will be made of: the encrypted string + spreader (the spreader field on IEncryptionConfig) + the rest of the value string that hasn't been encrypted.
   * @param value The string value to encrypt
   * @param key The encryption key
   * @returns The encrypted value string result
   */
  encrypt(value: string, key: string): string {
    try {
      if (!value || !key) return '';

      const {
        prefixPublicKey,
        suffixPublicKey,
        defaultKeySize,
        encryptedSubstringLength,
        encryptedSubstringDelimiter
      } = this.encryptionConfig;

      const publicKey = prefixPublicKey.concat(key, suffixPublicKey);

      /**
       * @description Creates a jsencrypt instance which expects to recive key strings with length of defaultKeySize
       */
      const crypto = new JSEncrypt({ default_key_size: defaultKeySize });
      crypto.setPublicKey(publicKey);

      /**
       * @description Takes the first encryptedSubstringLength value length from the value string (only part of the string is being encrypted during the process)
       */
      const shortBase64 = value.substring(0, encryptedSubstringLength);
      const encryptedShortBase64 = crypto.encrypt(shortBase64);

      if (!encryptedShortBase64) throw new Error('Encryption has failed, for unknown reason');

      return (
        encryptedShortBase64 + encryptedSubstringDelimiter + value.substring(encryptedSubstringLength, value.length)
      );
    } catch (e: any) {
      throw new Error(`Encryption has failed, reason: ${e.message}`);
    }
  }
}
