import { IHttpConfig, IHttpHeaders, THttpActionNames } from '@cal2Deliver/core';
import { environment } from '../../environments/environment';

export const httpRoutesMap = new Map<THttpActionNames, string>()
  .set('customerOtpCheck', '/login/customerOtpCheck')
  .set('customerOtp', '/login/customerOtp')
  .set('externalUserLogin', '/login/externalUeserLogin')
  .set('externalUserOtp', '/login/externalUeserOtp')
  .set('isExistToken', '/login/isExistToken')
  .set('docs', '/sale/docs')
  .set('forms', '/sale/forms')
  .set('saveFiles', '/sale/saveFiles')
  .set('submitDelivery', '/sale/submitDelivery');

export const httpHeaders: IHttpHeaders = {
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Origin': '*',
  'Cache-Control': 'no-cache',
  Accept: 'application/json'
};

export const httpConfig: IHttpConfig = {
  apiUrl: environment.apiUrl,
  useProxy: environment.useProxy,
  requestsMap: httpRoutesMap,
  httpHeaders
};
