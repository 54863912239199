import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ctdScreenMargin]',
  standalone: true
})
export class ScreenMarginDirective {
  @HostBinding('class')
  get hostClass() {
    return `screen-margin`;
  }
}
