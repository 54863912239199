export type TFeatureName =
  | 'authentication'
  | 'documents'
  | 'scan'
  | 'identification'
  | 'approval'
  | 'customer-authentication';

export interface IFeatureConfig {
  name: string;
  title: string;
  route: string;
  order: number;
}

export class Features {
  private static _featureMap = new Map<TFeatureName, IFeatureConfig>()
    .set('authentication', {
      title: 'זיהוי שליח',
      name: 'authentication',
      route: 'authentication',
      order: 1
    })
    .set('documents', {
      title: 'מסמכים ',
      name: 'documents',
      route: 'documents',
      order: 5
    })
    .set('scan', {
      title: 'סריקת תעודה',
      name: 'scan',
      route: 'scan',
      order: 4
    })
    .set('identification', {
      title: 'אישור זהות לקוח',
      name: 'identification',
      route: 'identification',
      order: 3
    })
    .set('approval', {
      title: 'סיום התליך',
      name: 'approval',
      route: 'approval',
      order: 6
    })
    .set('customer-authentication', {
      title: 'זיהוי לקוח',
      name: 'customer-authentication',
      route: 'customer-authentication',
      order: 2
    });

  static get(name: TFeatureName) {
    if (!Features._featureMap.has(name)) {
      throw new Error(`Could not find "${name}" feature`);
    }
    return Features._featureMap.get(name);
  }

  static getAll() {
    return [...this._featureMap.values()].sort((a, b) => (a.order < b.order ? -1 : 0));
  }
}
