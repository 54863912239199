<form [formGroup]="documentsFormGroup">
  <ng-container formArrayName="signatures">
    <ng-container *ngFor="let file of pdfFiles; let i = index; trackBy: trackByIndex">
      <ctd-document-item
        *ngIf="formIndex === i"
        [formControlName]="i"
        [file]="file"
        [pdfForm]="forms[i]"
        (documentApproved)="onDocumentApproved(i)"
      ></ctd-document-item>
    </ng-container>
  </ng-container>
</form>
