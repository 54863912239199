import { CameraInputValidators } from '@cal2Deliver/ui';
import { idNumberValidator } from './id-modulu10-validator';
import { lengthValidator } from './length-validator';

export class CustomValidators {
  static image = CameraInputValidators.imageValidator;
  static idValidator() {
    return idNumberValidator();
  }
  static lengthValidator(length: number) {
    return lengthValidator(length);
  }
}
