import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { IHttpResponse } from '../models';
import { THttpActionNames } from '../types';
import { HttpConfigService } from './http-config.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient, private configService: HttpConfigService) {}

  /**
   * @description wrapper method for angular's http GET method
   * @param args he actual params needed for the request
   * @param actionName  key in order to extract the full http action url
   * @param httpHeaders optional http headers
   * @param context custom http context used to customized http calls
   * example: display/hide loader etc'
   * @returns Observable<ICalHttpResponse<R>>
   */
  get<T, R>(
    actionName: THttpActionNames,
    args?: T,
    context?: HttpContext,
    httpHeaders?: HttpHeaders
  ): Observable<IHttpResponse<R>> {
    const url = this.configService.createActionUrl(actionName);

    const params = this.createHttpParams(args);
    const headers = this.createHttpHeaders(httpHeaders);
    return this.http.get(url, { context, params, headers }) as Observable<IHttpResponse<R>>;
  }
  /**
   * @description wrapper method for angular's http post method
   * @param model he actual model needed for the request
   * @param actionName  key in order to extract the full http action url
   * @param context custom http context used to customized http calls
   * example: display/hide loader etc'
   * @param httpHeaders optional http headers
   * @returns Observable<ICalHttpResponse<R>>
   */
  post<T, R>(
    actionName: THttpActionNames,
    model?: T,
    context?: HttpContext,
    httpHeaders?: HttpHeaders
  ): Observable<IHttpResponse<R>> {
    const url = this.configService.createActionUrl(actionName);
    const headers = this.createHttpHeaders(httpHeaders);
    return this.http.post(url, model, { context, headers }) as Observable<IHttpResponse<R>>;
  }
  /**
   * @description wrapper method for angular's http put method
   * @param model he actual model needed for the request
   * @param actionName  key in order to extract the full http action url
   * @param context custom http context used to customized http calls
   * example: display/hide loader etc'
   * @param httpHeaders optional http headers
   * @returns Observable<ICalHttpResponse<R>>
   */
  put<T, R>(
    actionName: THttpActionNames,
    model?: T,
    context?: HttpContext,
    httpHeaders?: HttpHeaders
  ): Observable<IHttpResponse<R>> {
    const url = this.configService.createActionUrl(actionName);
    const headers = this.createHttpHeaders(httpHeaders);
    return this.http.put(url, model, { context, headers }) as Observable<IHttpResponse<R>>;
  }

  delete<T, R>(
    actionName: THttpActionNames,
    parameters: T,
    context?: HttpContext,
    httpHeaders?: HttpHeaders
  ): Observable<IHttpResponse<R>> {
    const url = this.configService.createActionUrl(actionName);
    const params = this.createHttpParams(parameters);
    const headers = this.createHttpHeaders(httpHeaders);
    return this.http.delete(url, { context, params, headers }) as Observable<IHttpResponse<R>>;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createHttpParams(parameters: any) {
    return new HttpParams({ fromObject: parameters });
  }
  /**
   * @description this. method will get and optional headers as a param.
   * if headers param has value, we will override the current header property or add a new one.
   * @param headers
   * @returns HttpHeaders
   */
  private createHttpHeaders(headers?: HttpHeaders): HttpHeaders {
    const { httpHeaders } = this.configService.httpConfig;
    const createdHttpHeaders = new HttpHeaders(httpHeaders);
    if (!headers) {
      return createdHttpHeaders;
    }

    headers
      .keys()
      .filter((key) => !!key)
      .filter((key) => headers.has(key))
      .forEach((key: string) => {
        createdHttpHeaders.set(key, headers.get(key) as string);
      });

    return createdHttpHeaders;
  }
}
