import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function modulu10Validation(value) {
  const minLength = 4;
  const maxLength = 9;
  let mone = 0;
  let incNum: number;

  if (/^[0]*$/.test(value)) {
    return false;
  }

  const { length } = value;

  if (length > maxLength || length < minLength) {
    return false;
  }

  value = value.toString().padStart(maxLength, 0);

  for (let i = 0; i < maxLength; i++) {
    incNum = Number(value.charAt(i));
    incNum *= (i % 2) + 1;
    if (incNum > maxLength) incNum -= maxLength;
    mone += incNum;
  }

  return mone % 10 === 0;
}

export function idNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.invalid) return null;

    const invalid = { idNumber: { message: null } };

    if (!control.value) {
      return null;
    }

    const isValid = modulu10Validation(control.value);

    if (!isValid) {
      return invalid;
    }

    return null;
  };
}
