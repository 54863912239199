<div
  class="scanned-doc"
  [class.has-content]="hasContent$ | async"
  [ngStyle]="{ filter: enabled ? 'grayscale(0) blur(0px)' : 'grayscale(0.8) blur(5px)' }"
>
  <div class="scanned-doc--background">
    <mat-icon>photo_camera</mat-icon>
  </div>

  <div #input (click)="openScanovate()">
    <label class="scanned-doc--input">
      <ng-container *ngIf="image$ | async as image">
        <div class="scanned-doc--image" [@enterAnimation]>
          <img [src]="image" />
        </div>
      </ng-container>
    </label>
  </div>

  <div class="scanned-doc--description">
    <mat-icon *ngIf="(hasContent$ | async) === true">check_circle_outline</mat-icon>
    <span>{{ description }} </span>
  </div>

  <div class="scanned-doc--actions">
    <ng-container *ngIf="hasContent$ | async; else emptyAction">
      <ctd-minifab-button type="button" [@enterAnimation] (click)="requestRemovePicture()">
        <mat-icon class="remove-icon">delete</mat-icon>
      </ctd-minifab-button>
      <ctd-minifab-button type="button" [@enterAnimation] (click)="requestViewImage()">
        <mat-icon class="visibility-icon">visibility</mat-icon>
      </ctd-minifab-button>
    </ng-container>
  </div>
</div>

<ng-template #emptyAction>
  <ctd-minifab-button type="button" [isCallToAction]="true" [@enterAnimation] (click)="input.click()">
    <mat-icon class="add-icon">add</mat-icon>
  </ctd-minifab-button>
</ng-template>

<ctd-scanovate-camera
  *ngIf="isScanovateOpen$ | async"
  [docType]="docType"
  [scanConfig]="scanConfig"
  (pictureTaken)="onPictureTaken($event)"
  (pendingCancel)="onCancel()"
  (pendingError)="onError()"
></ctd-scanovate-camera>
