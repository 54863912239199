import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '../../../core/loader';
import { IdentificationService } from '../../identification/services';

@Injectable({
  providedIn: 'root'
})
export class DocumentsGuard implements CanActivate {
  constructor(private identificationService: IdentificationService, private loaderService: LoaderService) {}

  /**
   * @description The forms get http request response size is very big, and therfore might take some time to load.
   * Due to this condition we let the user progress in the process while the data is loading.
   *
   * The canActivate function checks if the request promise exists, and if it doesn't, creates a new request.
   * Then it'll await for the request response, and continue to the feature.
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.loaderService.turnOn();

    if (!this.identificationService.getFormsHttpRequest()) {
      this.identificationService.loadForms();
    }

    try {
      await this.identificationService.getFormsHttpRequest();
      return true;
    } catch (e) {
      throw new Error((e as Error).message);
    } finally {
      this.loaderService.turnOff();
    }
  }
}
