export class RegExpPatterns {
  static hebrewChars = /^[א-ת]*$/;
  static hebrewName = /^[א-ת-" ]*$/;
  static englishName = /^[a-zA-Z-_' ]*$/;
  static engVowls = /[AOUIEUY]{1,}/;
  static email = /^$|^([0-9a-zA-Z_]([-.\w]*[0-9a-zA-Z_])*@(([0-9a-zA-Z])+([-\w]*[0-9a-zA-Z])*\.)+[a-zA-Z]{2,9})$/;
  static phoneNumber = /^[0][5][0|1|2|3|4|5|7|8]{1}[-]{0,1}[1-9]{1}[0-9]{6}$/;
  static numeric = /^\d*$/;
  static nonZeroDigits = /^[1-9]*$/;
  static hebConsecutive3 = /([א-ת])\1\1/;
  static hebConsecutive2 = /([א-ת])\1/;
  static engConsecutive3 = /([A-Za-z])\1\1/;
  static engConsecutive2 = /([A-Za-z])\1/;
  static hebConsecutive1 = /([-])+/;
  static localeDate = /\d{2}\/\d{2}\/\d{4}?/;
}
