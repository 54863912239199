import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(private router: Router) {}

  moveTo(feature: string) {
    if (!feature) return;
    this.router.navigateByUrl(feature);
  }
}
