import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '@cal2Deliver/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Features } from '../config';
import { RootActions } from './root.actions';

@Injectable({
  providedIn: 'root'
})
export class RootEffects {
  resetEffect$: Observable<Action> = createEffect(
    (): any =>
      this.actions$.pipe(
        ofType(RootActions.reset),
        tap(() => {
          const authRoute = Features.get('authentication')?.route;
          this.routeService.moveTo(authRoute);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private routeService: RoutingService) {}
}
