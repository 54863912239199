/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { RoutingService } from '@cal2Deliver/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { Features } from '../../../config/features.config';
import { CustomerAuthenticationActions } from './customer-authentication-actions';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthenticationEffects {
  /* resetEffect$: Observable<Action> = createEffect(
    (): any => this.actions$.pipe(ofType(CustomerAuthenticationActions.reset)),
    { dispatch: false }
  );*/

  submitOtpCheckEffect$: Observable<Action> = createEffect(
    (): any =>
      this.actions$.pipe(
        ofType(CustomerAuthenticationActions.otpCheckSubmitSuccess),
        tap(({ otpCheckData }) => {
          //if no otp requires, we can navigate to next page
          if (!otpCheckData.isOtpRequired) {
            const route = Features.get('identification')?.route;
            this.routerService.moveTo(route);
          }
        })
      ),
    { dispatch: false }
  );
  submitOtpEffect$: Observable<Action> = createEffect(
    (): any =>
      this.actions$.pipe(
        ofType(CustomerAuthenticationActions.otpSubmitSuccess),
        tap(() => {
          const route = Features.get('identification')?.route;
          this.routerService.moveTo(route);
        })
      ),
    { dispatch: false }
  );
  constructor(private actions$: Actions, private routerService: RoutingService) {}
}
