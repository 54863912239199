import { IApiError, IBaseError, IClientError, INetworkError } from './error.interface';
import { TError } from './error.type';

export abstract class BaseError implements IBaseError {
  constructor(
    public errorType: TError,
    public title?: string,
    public description?: string,
    public message?: string,
    public stack?: string,
    public name?: string,
    public messageCode?: string
  ) {}
}

//An error came from http call (bushiness or technical)
export class ApiError extends BaseError implements IApiError {
  constructor(public statusCode: number, ...error: ConstructorParameters<typeof BaseError>) {
    super(...error);
  }
}
//Run time code or unexpected error
export class ClientError extends BaseError implements IClientError {
  constructor(...error: ConstructorParameters<typeof BaseError>) {
    super(...error);
  }
}

//No internet connection error.
export class NetworkError extends BaseError implements INetworkError {
  constructor(public statusCode: number, ...error: ConstructorParameters<typeof BaseError>) {
    super(...error);
  }
}
