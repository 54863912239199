import { Action, createReducer, on } from '@ngrx/store';
import { LoaderActions } from './loader-actions';
import { ILoaderState } from './loader-state';

export const initialLoaderState: ILoaderState = {
  pending: false
};

export const loaderReducerFn = createReducer(
  initialLoaderState,
  on(LoaderActions.turnLoaderOff, (state: ILoaderState) => {
    return {
      ...state,
      pending: false
    };
  }),
  on(LoaderActions.turnLoaderOn, (state: ILoaderState) => {
    return {
      ...state,
      pending: true
    };
  })
);
export function loaderReducer(state: ILoaderState, action: Action = undefined) {
  return loaderReducerFn(state, action);
}
