import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetContainerComponent, FlatButtonComponent, IErrorDialog } from '@cal2Deliver/ui';

@Component({
  standalone: true,
  imports: [CommonModule, FlatButtonComponent, AssetContainerComponent],
  template: ` <ctd-container-asset
      path="./assets/svg/rounded-icon--error.svg"
      class="icon-asset--rounded icon-asset--dialog-header icon-asset--lg"
    ></ctd-container-asset>
    <h1 mat-dialog-title>{{ data.title || 'שגיאה' }}</h1>
    <div mat-dialog-content>
      {{ data.message || 'אנחנו מצטערים אך קרתה תקלה' }}
    </div>
    <div mat-dialog-actions>
      <ctd-flat-button class="btn btn--sm" type="button" mat-dialog-close (click)="close()">
        <span>סגור</span>
      </ctd-flat-button>
    </div>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IErrorDialog, private dialogRef: MatDialogRef<unknown>) {}

  close() {
    this.dialogRef.close(this.data);
  }
}
